export default class Hotel {
    constructor({
        id = null,
        organization_id = null,
        name = null,
        company_name = null,
        hotel_chain_name = null,
        is_hotel_chain = null,
        star_rating = null,
        hotel_types = [],
        seasonal_timings = null,
        address = null,
        map_location = null,
        city = null,
        country = null,
        intro_title = null,
        description = null,
        checkin_time = null,
        checkout_time = null,
        restaurants = [],
        is_food_and_beverage_available = true,
        is_bar_available = true,
        is_restaurant_available = true,
        bars = [],
        event_spaces = [],
        ptcs = [],
        activities = [],
        full_name = null,
        company = null,
        job_title = null,
        email = null,
        phone = null,
        profile_picture = null,
        secondary_full_name = null,
        secondary_company = null,
        secondary_job_title = null,
        secondary_email = null,
        secondary_phone = null,
        secondary_profile_picture = null,
        status = null,
        type = null,
        hotel_features = [],
        hotel_specifications = null,
        gallery = null,
        rooms = [],
        drafts = []
    } = {}) {
        this.id = id;
        this.organization_id = organization_id;
        this.name = name;
        this.company_name = company_name;
        this.hotel_chain_name = hotel_chain_name;
        this.is_hotel_chain = is_hotel_chain;
        this.star_rating = star_rating;
        this.hotel_types = hotel_types;
        this.seasonal_timings = seasonal_timings;
        this.address = address;
        this.map_location = map_location;
        this.city = city;
        this.country = country;
        this.intro_title = intro_title;
        this.description = description;
        this.checkin_time = checkin_time;
        this.checkout_time = checkout_time;
        this.restaurants = restaurants;
        this.is_food_and_beverage_available = is_food_and_beverage_available;
        this.is_bar_available = is_bar_available;
        this.is_restaurant_available = is_restaurant_available;
        this.bars = bars;
        this.event_spaces = event_spaces;
        this.ptcs = ptcs;
        this.hotel_specifications = hotel_specifications;
        this.activities = activities;
        this.full_name = full_name;
        this.company = company;
        this.job_title = job_title;
        this.email = email;
        this.phone = phone;
        this.profile_picture = profile_picture;
        this.secondary_full_name = secondary_full_name;
        this.secondary_company = secondary_company;
        this.secondary_job_title = secondary_job_title;
        this.secondary_email = secondary_email;
        this.secondary_phone = secondary_phone;
        this.secondary_profile_picture = secondary_profile_picture;
        this.status = status;
        this.type = type;
        this.hotel_features = hotel_features;
        this.gallery = gallery;
        this.rooms = rooms;
        this.drafts = drafts;
    }
}
