import API from '@/services/api';
import Plan from "../models/Plan";
import { getField, updateField } from 'vuex-map-fields';

const initialState = () => ({
    newPlan: new Plan(),
    plan: new Plan(),
    plans: [],
    planLoadStatus: 0,
    plansLoadStatus: 0,
    planAddStatus: 0,
    planUpdateStatus: 0,
    planDeleteStatus: 0,
    planErrors: {},
});

export default {
    state: initialState(),
    actions: {
        async loadPlans({ commit, state, dispatch }, payload = {} ) {
            commit( 'setPlansLoadStatus', 1 );
            let response = await API.getList('Plan', payload);
            if(response.status === 200)
            {
                commit( 'setPlans', response.data.data );
                commit( 'setPlansLoadStatus', 2 );
            } else {
                commit( 'setPlansLoadStatus', 3 );
            }
        },
        async loadPlan({ commit, state, rootState, getters }, payload = {} ) {
            if(payload.hasOwnProperty('mlforceload') || payload.id !== getters.getActivePlan ) {
                if(!payload.hasOwnProperty('mlsilent')) commit( 'setPlanLoadStatus', 1 );
                let response = await API.getOne('Plan', payload);
                if(response.status === 200) {
                    commit( 'setPlan', response.data.data );
                    commit( 'updatePlanList', response.data.data );
                    commit( 'setPlanLoadStatus', 2 );
                } else {
                    commit( 'setPlanLoadStatus', 3 );
                }
            }
        },
        async addPlan({ commit, state }, payload = {} ) {
            commit( 'setPlanAddStatus', 1 );
            let response = await API.post( 'Plan', { ...state.newPlan, ...payload } );
            if(response.status === 200 || response.status === 201){
                commit( 'setPlanAddStatus', 2 );
                commit( 'resetNewPlan' );
                commit( 'resetPlanErrors' );
                commit( 'updatePlanList', response.data.data );
                commit( 'setPlan', response.data.data );
                commit( 'setPlanLoadStatus', 2 );
            } else if(response.status === 422){
                commit( 'setPlanAddStatus', 3 );
                commit( 'setPlanErrors', response.data.errors );
            } else {
                commit( 'setPlanAddStatus', 3 );
                commit( 'setPlanErrors', {} );
            }
        },
        async updatePlan({ commit, state }, payload = {} ) {
            commit( 'setPlanUpdateStatus', 1 );
            let response = await API.put( 'Plan', { ...state.plan, ...payload } );
            if(response.status === 200 || response.status === 202) {
                commit( 'setPlanUpdateStatus', 2 );
                commit( 'resetPlanErrors' );
                //commit( 'updatePlanList', response.data.data );
            } else if(response.status === 422) {
                commit( 'setPlanUpdateStatus', 3 );
                commit( 'setPlanErrors', response.data.errors );
            } else {
                commit( 'setPlanUpdateStatus', 3 );
                commit( 'setPlanErrors', {} );
            }
        },
        async deletePlan({ commit, state }, payload = {} ) {
            commit('setPlanDeleteStatus',1);
            let response = await API.delete( 'Plan', payload );
            if(response.status === 200) {
                commit('setPlanDeleteStatus',2);
                commit('removePlan', payload);
            }else if(response.status === 444){
                commit('setPlanDeleteStatus',3);
                commit( 'setPlanErrors', {'error':response.data.message} );
            }
        }
    },
    mutations: {
        setPlanFields( state, field ) { updateField(state.plan, field); },
        setNewPlanFields( state, field ) { updateField(state.newPlan, field); },
        setPlanLoadStatus( state, status ) { state.planLoadStatus = status; },
        setPlansLoadStatus( state, status ) { state.plansLoadStatus = status; },
        setPlanAddStatus( state, status ) { state.planAddStatus = status; },
        setPlan( state, plan ) { state.plan = plan; },
        setNewPlan( state, plan ) { state.newPlan = plan; },
        setPlanUpdateStatus( state, plan ) { state.planUpdateStatus = plan; },
        setPlans( state, plans ) { state.plans = plans; },
        setPlanDeleteStatus(state, status){ state.planDeleteStatus = status;},
        setPlanById( state, id ) { state.plan = state.plans.find(o => o.id === id); },
        setPlanErrors( state, errors ) { state.planErrors = errors; },
        updatePlanListStatus( state, plan = null ) {
            const obj = state.plans.find(o => o.id === plan.plan_id);
            let updatedObj = obj;
            updatedObj.status = plan.status;
            if(obj) Object.assign( obj, updatedObj);
        },
        updatePlanList( state, plan = null ) {
            const obj = plan ? state.plans.find(o => o.id === plan.id) : state.plans.find(o => o.id === state.plan.id);
            if(obj) Object.assign( obj, plan ? plan : state.plan );
            else state.plans.push(plan);
            if(plan && !!state.plan.id) state.plan = plan;
        },
        resetNewPlan(state) { state.newPlan = new Plan() },
        resetPlanErrors(state) { state.planErrors = {} },
        ResetPlan(state) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                state[key] = newState[key]
            });
        },
        removePlan(state, plan){
            let removeIndex = state.plans.indexOf(plan);
            state.plans.splice(removeIndex, 1);
        }
    },

    getters: {
        getPlanFields: state => getField(state.plan),
        getNewPlanFields: state => getField(state.newPlan),
        getNewPlan: state => state.newPlan,
        getPlan: state => state.plan,
        getPlans: state => state.plans,
        getActivePlan: state => !!state.plan.id ? state.plan.id : null,
        getPlanErrors: state => state.planErrors,
        getPlanLoadStatus: state => state.planLoadStatus,
        getPlansLoadStatus: state => state.plansLoadStatus,
        getPlanAddStatus: state => state.planAddStatus,
        getPlanUpdateStatus: state => state.planUpdateStatus,
        getPlanDeleteStatus: state => state.planDeleteStatus,
    }
}
