import API from "@/services/api";
import Account from "../models/Account";
import { getField, updateField } from "vuex-map-fields";

const initialState = () => ({
    newAccount: new Account(),
    account: new Account(),
    accounts: [],
    parentChildAccounts: [],
    accountSearch: [],
    accountLoadStatus: 0,
    accountsLoadStatus: 0,
    accountAddStatus: 0,
    accountUpdateStatus: 0,
    accountDeleteStatus: 0,
    accountSearchStatus: 0,
    accountErrors: {},
    accountUserUpdatedStatus: 0,
    accountPage: 1
});

export default {
    state: initialState(),
    actions: {
        async loadAccounts({ commit, state, dispatch }, payload = {}) {
            if(payload.hasOwnProperty('mlforceload') || _.isEmpty(state.organizations) ) {
                if (!payload.hasOwnProperty('mlsilent')) commit('setAccountsLoadStatus', 1);
                // let response = await API.getList("Account", { page: state.accountPage });
                let response = await API.getList("Account",payload);
                if (response.status === 200) {
                    let accounts = _.orderBy(response.data.data, ["created_at"], ["desc"]);
                    commit("setAccounts", accounts);
                    commit("setAccountsLoadStatus", 2);
                    // if (!state.account.id && !!accounts.length > 0) {
                    //     await dispatch("loadAccount", {id: accounts[0].id});
                    // } else commit("setAccountLoadStatus", 2);
                    // return accounts.length;
                } else {
                    commit("setAccountsLoadStatus", 3);
                    // return -1;
                }
            }
        },
        async loadAccount({ commit, state, rootState, getters }, payload = {}) {
            if (payload.hasOwnProperty("mlforceload") || payload.id !== getters.getActiveAccount) {
                if (!payload.hasOwnProperty("mlsilent")) commit("setAccountLoadStatus", 1);
                let response = await API.getOne("Account", payload);
                if (response.status === 200) {
                    commit("setAccount", response.data.data);
                    commit("updateAccountList", response.data.data);
                    commit("setAccountLoadStatus", 2);
                } else {
                    commit("setAccountLoadStatus", 3);
                }
            }
        },
        async addAccount({ commit, state }, payload = {}) {
            commit("setAccountAddStatus", 1);
            let response = await API.post("Account", { ...state.newAccount, ...payload });
            if(response.status === 200 || response.status === 201){
                commit("setAccountAddStatus", 2);
                commit("resetNewAccount");
                commit("resetAccountErrors");
                commit("updateAccountList", response.data.data);
                commit("setAccount", response.data.data);
                commit("setAccountLoadStatus", 2);
            } else if (response.status === 422) {
                commit("setAccountAddStatus", 3);
                commit("setAccountErrors", response.data.errors);
            } else {
                commit("setAccountAddStatus", 3);
                commit("setAccountErrors", {});
            }
        },
        async updateAccount({ commit, state }, payload = {}) {
            commit("setAccountUpdateStatus", 1);
            let response = await API.put("Account", { ...state.account, ...payload });
            if (response.status === 200 || response.status === 202) {
                commit("setAccountUpdateStatus", 2);
                commit("resetAccountErrors");
                commit("updateAccountList");
            } else if (response.status === 422) {
                commit("setAccountUpdateStatus", 3);
                commit("setAccountErrors", response.data.errors);
            } else {
                commit("setAccountUpdateStatus", 3);
                commit("setAccountErrors", {});
            }
        },
        async updateAccountUser({ commit, state }, payload = {}) {
            commit("setAccountUserUpdatedStatus", 1);
            let response = await API.post("AccountUser", payload);
            if (response.status === 200) {
                commit("setAccountUserUpdatedStatus", 2);
                commit("setAccount", response.data.data);
            } else if (response.status === 422) {
                commit("setAccountUserUpdatedStatus", 3);
                commit("setAccountErrors", response.data.errors);
            } else {
                commit("setAccountUserUpdatedStatus", 3);
                commit("setAccountErrors", {});
            }
        }
    },
    mutations: {
        addAccountsToState(state, accounts) {
            _.forEach(accounts, function(a) {
                if (!state.accounts.some(o => o.id === a.id)) state.accounts.push(a);
            });
            state.accountPage += 1;
        },
        setAccountFields(state, field) {
            updateField(state.account, field);
        },
        setNewAccountFields(state, field) {
            updateField(state.newAccount, field);
        },
        setAccountLoadStatus(state, status) {
            state.accountLoadStatus = status;
        },
        setAccountsLoadStatus(state, status) {
            state.accountsLoadStatus = status;
        },
        setAccountAddStatus(state, status) {
            state.accountAddStatus = status;
        },
        setAccount(state, account) {
            state.account = account;
        },
        setNewAccount(state, account) {
            state.newAccount = account;
        },
        setAccountUpdateStatus(state, status) {
            state.accountUpdateStatus = status;
        },
        setAccounts(state, accounts) {
            state.accounts = accounts;
        },
        setAccountUserUpdatedStatus(state, status) {
            state.accountUserUpdatedStatus = status;
        },
        mergeLocationsToAccounts(state, locations) {
            let accounts = state.accounts;
            _.forEach(locations, location => {
                accounts.push(location);
            });
            state.accounts = accounts;
        },
        setAccountErrors(state, errors) {
            state.accountErrors = errors;
        },
        updateAccountListStatus(state, account = null) {
            const obj = state.accounts.find(o => o.id === account.account_id);
            let updatedObj = obj;
            updatedObj.status = account.status;
            if (obj) Object.assign(obj, updatedObj);
        },
        setAccountByAccountId: (state, accountId=null)=>{
            state.account =  state.accounts.find(account => account.id === accountId);
        },
        updateAccountList(state, account = null) {
            const obj = account
                ? state.accounts.find(o => o.id === account.id)
                : state.accounts.find(o => o.id === state.account.id);
            if (obj) Object.assign(obj, account ? account : state.account);
            else state.accounts.push(account);
            if (account && !!state.account.id) state.account = account;
        },
        updateOrganizationInAccounts(state, updatedOrg) {
            // let accounts = [];
            // state.accounts.forEach(account => {
            //     account.organizations.forEach((org, i) => {
            //         if (org.id === updatedOrg.id) {
            //             account.organizations[i] = updatedOrg;
            //         }
            //     });
            //     accounts.push(account);
            // });
            // state.accounts = accounts;
        },
        resetNewAccount(state) {
            state.newAccount = new Account();
        },
        resetAccountErrors(state) {
            state.accountErrors = {};
        },
        ResetAccount(state) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                state[key] = newState[key];
            });
        },
        updateAccountPage(state,page){
            state.accountPage = page;
        }
    },

    getters: {
        getAccountFields: state => getField(state.account),
        getNewAccountFields: state => getField(state.newAccount),
        getNewAccount: state => state.newAccount,
        getAccount: state => state.account,
        getAccounts: state => state.accounts,
        getActiveAccount: state => (!!state.account.id ? state.account.id : null),
        getAccountErrors: state => state.accountErrors,
        getAccountLoadStatus: state => state.accountLoadStatus,
        getAccountsLoadStatus: state => state.accountsLoadStatus,
        getAccountAddStatus: state => state.accountAddStatus,
        getAccountUpdateStatus: state => state.accountUpdateStatus,
        getAccountDeleteStatus: state => state.accountDeleteStatus,
        getAccountUserUpdatedStatus: state => state.accountUserUpdatedStatus,
        getAccountsByAccountId: state => accountId => state.accounts.filter(account => account.account_id === accountId)
    }
};
