import API from '@/services/api';
import Conversation from "../models/Conversation";
import Message from "../models/Message";

const initialState = () => ({
    newMessage: new Message(),
    requestMessage: null,
    requestMessageSendStatus: 0,
    requestMessageErrors: {},
    conversation: [],
    conversationSearch: [],
    scrollCount:1,
});

export default {
    state: initialState(),
    actions: {
        async sendRequestMessage({ commit, state, getters}, payload = {} ) {
            commit( 'setRequestMessageSendStatus', 1 );
            commit('addMessageConversation', state.newMessage);
            let response = await API.post( 'Message', { ...state.newMessage, ...payload } );
            if(response.status === 201){
                commit( 'resetRequestMessage' );
                commit( 'resetRequestMessageErrors' );
                commit( 'updateConversationList', response.data.data );
                commit( 'updateRequestListUpdatedAt', getters.getRequest);
                commit( 'setRequestMessageSendStatus', 2 );
            } else if(response.status === 422){
                commit( 'setRequestMessageSendStatus', 3 );
                commit( 'resetRequestMessageErrors', response.data.errors );
            } else {
                commit( 'resetRequestMessageErrors', 3 );
                commit( 'setConversationErrors', {} );
            }
        },
        async sendAttachmentMessage({ commit, state,getters }, payload = {} ) {
            commit( 'setRequestMessageSendStatus', 1 );
            let response = await axios.post( '/api/v1/messages',payload,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: "Bearer " + getters.getAuthToken
                },
            }).then((response) => {
                commit( 'resetRequestMessage' );
                commit( 'resetRequestMessageErrors' );
                commit( 'updateConversationList', response.data.data );
            });
        },
    },
    mutations: {
        setRequestMessageSendStatus( state, status ) { state.requestMessageSendStatus = status; },
        setConversation( state, conversation ) { state.conversation = conversation; },
        setNewMessage( state, message ) { state.newMessage = message; },
        resetRequestMessage(state) { state.requestMessage = new Conversation() },
        resetRequestMessageErrors(state) { state.requestMessageErrors = {} },
        addMessageConversation(state, message){ state.conversation.push(message) },
        updateConversationList( state, message ) {
            const obj = state.conversation.find(o => o.id === null);
            if(obj)  Object.assign(obj, message ) ;
            else state.conversation.push(message);
        },
        resetScrollCount( state, status ) { state.scrollCount = 1; },
        incrementScrollCount( state, status ) { state.scrollCount = state.scrollCount+1; },

    },

    getters: {
        getRequestMessage: state => state.requestMessage,
        getConversation: state => state.conversation,
        getActiveConversation: state => !!state.conversation.id ? state.conversation.id : null,
        getRequestMessageSendStatus: state => state.requestMessageSendStatus,
        getRequestMessageErrors: state => state.requestMessageErrors,
        getScrollCount: state => state.scrollCount,
    }
}
