export default class VendorSpecifications {
    constructor({
        id = null,
        hotel_id = null,
        is_reception_always_available = false,
        has_event_space = true,
        has_meeting_space = false,
        has_activities = false,
        is_restaurant = null,
        is_bar = null,
        total_meeting_spaces = null,
        total_parking_spaces = null,
        status = null,
        location_id = null,
        has_high_power_connection = null,
        has_parking = null,
        total_breakout_rooms = null
    } = {}) {
        this.id = id;
        this.hotel_id = hotel_id;
        this.is_reception_always_available = is_reception_always_available;
        this.has_event_space = has_event_space;
        this.has_meeting_space = has_meeting_space;
        this.has_activities = has_activities;
        this.is_restaurant = is_restaurant;
        this.is_bar = is_bar;
        this.total_meeting_spaces = total_meeting_spaces;
        this.total_parking_spaces = total_parking_spaces;
        this.status = status;
        this.location_id = location_id;
        this.has_high_power_connection = has_high_power_connection;
        this.has_parking = has_parking;
        this.total_breakout_rooms = total_breakout_rooms;
    }
}
