import API from "@/services/api";
import Organization from "../models/Organization";
import { getField, updateField } from "vuex-map-fields";

const initialState = () => ({
    newOrganization: new Organization(),
    organization: new Organization(),
    organizations: [],
    parentChildOrganizations: [],
    organizationSearch: [],
    organizationLoadStatus: 0,
    organizationsLoadStatus: 0,
    organizationAddStatus: 0,
    organizationUpdateStatus: 0,
    organizationDeleteStatus: 0,
    organizationSearchStatus: 0,
    organizationErrors: {},
    organizationUserUpdatedStatus: 0
});

export default {
    state: initialState(),
    actions: {
        async loadOrganizations({ commit, state, dispatch }, payload = {}) {
            // if (_.isEmpty(payload) && state.organizations.length) {
            //     console.log("payload", payload);
            //     return;
            // }

            let response = await API.getList("Organization", payload);

            if (response.status === 200) {
                commit("setOrganizations", response.data.data);
                commit("setOrganizationsLoadStatus", 2);
            } else {
                commit("setOrganizationsLoadStatus", 3);
            }
        },
        async loadOrganization({ commit, state, rootState, getters }, payload = {}) {
            if (payload.hasOwnProperty("mlforceload") || payload.id !== getters.getActiveOrganization) {
                if (!payload.hasOwnProperty("mlsilent")) commit("setOrganizationLoadStatus", 1);
                let response = await API.getOne("Organization", payload);
                if (response.status === 200) {
                    commit("setOrganization", response.data.data);
                    commit("setOrganizationLoadStatus", 2);
                } else {
                    commit("setOrganizationLoadStatus", 3);
                }
            }
        },
        async addOrganization({ commit, state, dispatch }, payload = {}) {
            commit("setOrganizationAddStatus", 1);
            let response = await API.post("Organization", { ...state.newOrganization, ...payload });
            if (response.status === 200 || response.status === 201) {
                commit("setOrganizationAddStatus", 2);
                commit("resetNewOrganization");
                commit("resetOrganizationErrors");
                commit("setOrganization", response.data.data);
                commit("setOrganizationLoadStatus", 2);
                // commit( 'updateOrganizationInAccounts', response.data.data );
            } else if (response.status === 422) {
                commit("setOrganizationAddStatus", 3);
                commit("setOrganizationErrors", response.data.errors);
            } else {
                commit("setOrganizationAddStatus", 3);
                commit("setOrganizationErrors", {});
            }
        },
        async updateOrganization({ commit, state, dispatch }, payload = {}) {
            commit("setOrganizationUpdateStatus", 1);
            let response = await API.put("Organization", { ...state.organization, ...payload });
            if (response.status === 200 || response.status === 202) {
                commit("setOrganizationUpdateStatus", 2);
                commit("resetOrganizationErrors");
                // commit( 'updateOrganizationInAccounts', response.data.data );
            } else if (response.status === 422) {
                commit("setOrganizationUpdateStatus", 3);
                commit("setOrganizationErrors", response.data.errors);
            } else {
                commit("setOrganizationUpdateStatus", 3);
                commit("setOrganizationErrors", {});
            }
        },
        async updateOrganizationUser({ commit, state }, payload = {}) {
            commit("setOrganizationUserUpdatedStatus", 1);
            commit("setOverlay", true);
            let response = await API.post("OrganizationUser", payload);
            if (response.status === 200) {
                // commit( 'updateOrganizationInAccounts', response.data.data );
                commit("setOrganizationUserUpdatedStatus", 2);
                commit("setOrganization", response.data.data);
                commit("setOverlay", false);
            } else if (response.status === 422) {
                commit("setOrganizationUserUpdatedStatus", 3);
                commit("setOrganizationErrors", response.data.errors);
            } else {
                commit("setOrganizationUserUpdatedStatus", 3);
                commit("setOrganizationErrors", {});
            }
        }
    },
    mutations: {
        setOrganizationFields(state, field) {
            updateField(state.organization, field);
        },
        setNewOrganizationFields(state, field) {
            updateField(state.newOrganization, field);
        },
        setOrganizationLoadStatus(state, status) {
            state.organizationLoadStatus = status;
        },
        setOrganizationsLoadStatus(state, status) {
            state.organizationsLoadStatus = status;
        },
        setOrganizationAddStatus(state, status) {
            state.organizationAddStatus = status;
        },
        setOrganization(state, organization) {
            state.organization = organization;
        },
        setNewOrganization(state, organization) {
            state.newOrganization = organization;
        },
        setOrganizationUpdateStatus(state, organization) {
            state.organizationUpdateStatus = organization;
        },
        setOrganizations(state, organizations) {
            state.organizations = organizations;
        },
        setOrganizationUserUpdatedStatus(state, status) {
            state.organizationUserUpdatedStatus = status;
        },
        mergeLocationsToOrganizations(state, locations) {
            let organizations = state.organizations;
            _.forEach(locations, location => {
                organizations.push(location);
            });
            state.organizations = organizations;
        },
        setOrganizationErrors(state, errors) {
            state.organizationErrors = errors;
        },
        updateOrganizationListStatus(state, organization = null) {
            const obj = state.organizations.find(o => o.id === organization.organization_id);
            let updatedObj = obj;
            updatedObj.status = organization.status;
            if (obj) Object.assign(obj, updatedObj);
        },
        resetNewOrganization(state) {
            state.newOrganization = new Organization();
        },
        resetOrganizationErrors(state) {
            state.organizationErrors = {};
        },
        ResetOrganization(state) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                state[key] = newState[key];
            });
        }
    },

    getters: {
        getOrganizationFields: state => getField(state.organization),
        getNewOrganizationFields: state => getField(state.newOrganization),
        getNewOrganization: state => state.newOrganization,
        getOrganization: state => state.organization,
        getOrganizations: state => state.organizations,
        getActiveOrganization: state => (!!state.organization.id ? state.organization.id : null),
        getOrganizationErrors: state => state.organizationErrors,
        getOrganizationLoadStatus: state => state.organizationLoadStatus,
        getOrganizationsLoadStatus: state => state.organizationsLoadStatus,
        getOrganizationAddStatus: state => state.organizationAddStatus,
        getOrganizationUpdateStatus: state => state.organizationUpdateStatus,
        getOrganizationDeleteStatus: state => state.organizationDeleteStatus,
        getOrganizationUserUpdatedStatus: state => state.organizationUserUpdatedStatus,
        getOrganizationsByOrgId: state => organizationId =>
            state.organizations.filter(organization => organization.id === organizationId),
        getOrganizationByOrgId: state => organizationId =>
            state.organizations.find(organization => organization.id === organizationId),
        getRecursiveOrganizationByOrgId: state => organizationId => {
            let value = organizationId;
            let key = "id";
            let org = null;
            state.organizations.some(function iter(o) {
                if (o[key] === value) {
                    org = o;
                    return true;
                }
                return Array.isArray(o.children) && o.children.some(iter);
            });
            return org;
        },
        getOrganizationsWithVenues: state => state.organizations.filter(organization => organization.venue_count > 0)
    }
};
