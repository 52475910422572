export default class Subscription {
    constructor({
        id = null,
        account_id= null,
        organization_id = null,
        name= null,
        billing_period = null,
        currency = null,
        unit_price = null,
        trial_duration_days = null,
        grace_days = null,
        subscribed_on = null,
        trial_start = null,
        trial_end = null,
        period_start = null,
        period_end = null,
        tax_percentage = null,
        is_demo = null,
        status = null,
        created_at = null,
        updated_at = null,
        deleted_at = null,
    } = {}) {
        this.id = id;
        this.account_id= account_id;
        this.organization_id = organization_id;
        this.name= name;
        this.billing_period = billing_period;
        this.currency = currency;
        this.unit_price = unit_price;
        this.trial_duration_days = trial_duration_days;
        this.grace_days = grace_days;
        this.subscribed_on = subscribed_on;
        this.trial_start = trial_start;
        this.trial_end = trial_end;
        this.period_start = period_start;
        this.period_end = period_end;
        this.tax_percentage = tax_percentage;
        this.is_demo = is_demo;
        this.status = status;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.deleted_at = deleted_at;
    }
}