export default class Organization {
    constructor({
        id = null,
        account_id = null,
        parent_id = null,
        parent_name = null,
        parent = null,
        children = [],
        name = null,
        billing_email = null,
        contact_email = null,
        phone = null,
        address = null,
        vat_id = null,
        postal_code = null,
        city = null,
        country = null,
        color = null,
        users = [],
        is_demo = null,
        is_child = null,
        is_enabled = null,
        status = null,
        created_at = null,
        updated_at = null,
        deleted_at = null,
    } = {}) {
        this.id = id;
        this.account_id = account_id;
        this.parent_id = parent_id;
        this.parent_name = parent_name;
        this.parent = parent;
        this.children = children;
        this.name = name;
        this.billing_email = billing_email;
        this.contact_email = billing_email;
        this.phone = phone;
        this.address = address;
        this.vat_id = vat_id;
        this.postal_code = postal_code;
        this.city = city;
        this.country = country;
        this.color = color;
        this.users = users;
        this.is_demo = is_demo;
        this.is_child = is_child;
        this.is_enabled = is_enabled;
        this.status = status;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.deleted_at = deleted_at;
    }
}