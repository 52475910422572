export default class User {
    constructor({
        id = null,
        organization_id = null,
        first_name = null,
        last_name = null,
        name = null,
        initials = null,
        job_title = null,
        email = null,
        email_verified_at = null,
        password = null,
        user_type = null,
        user_role = null,
        phone = null,
        remember_token = null,
        is_demo = null,
        status = null,
        created_at = null,
        updated_at = null,
        deleted_at = null,
} = {}) {
        this.id = id;
        this.organization_id = organization_id;
        this.first_name = first_name;
        this.last_name = last_name;
        this.name = name;
        this.initials = initials;
        this.job_title = job_title;
        this.email = email;
        this.email_verified_at = email_verified_at;
        this.password = password;
        this.user_role = user_role;
        this.user_type = user_type,
        this.phone = phone;
        this.remember_token = remember_token;
        this.is_demo = is_demo;
        this.status = status;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.deleted_at = deleted_at;
    }
}