export default class Plan {
    constructor({
        id = null,
        name = null,
        currency= null,
        billing_period= null,
        unit_price = null,
        trial_duration_days= null,
        grace_days = null,
        is_enabled = null,
        is_default = 0,
        is_demo = null,
        status = null,
        created_at = null,
        updated_at = null,
        deleted_at = null,
    } = {}) {
        this.id = id;
        this.name = name;
        this.currency = currency;
        this.billing_period = billing_period;
        this.unit_price = unit_price;
        this.trial_duration_days = trial_duration_days;
        this.grace_days = grace_days;
        this.is_enabled = is_enabled;
        this.is_default = is_default;
        this.is_demo = is_demo;
        this.status = status;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.deleted_at = deleted_at;
    }
}