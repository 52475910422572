import API from "@/services/api";

const initialState = () => ({
    rules: {
        required: value => !!value || "Required.",
        requiredArray: value => !!value.length || "Required.",
        number: v => !isNaN(v) || "Not a numeric value",
        germanNumber: v => !isNaN(v ? v.toString().replace(',','.') : 0) || "Not a numeric value",
        nonZeroNumber: v => v > 0 || "Amount should be non-zero",
        isInteger: v => {
            if (v) {
                return (!isNaN(v) && parseInt(Number(v)) == v && !isNaN(parseInt(v, 10))) || "Invalid - (value in points is unacceptable)";
            }
            return true;
        },
        nonNegativeNumber: v => {
            const pattern = /-/;
            return !pattern.test(v) || "Amount should be non-negative";
        },
        nonNegativeQuantity: v => {
            const pattern = /-/;
            return !pattern.test(v) || "Number is invalid";
        },
        min: v => v.length >= 8 || "Min 8 characters",
        max: v => v < 100 || "Range exceeded",
        string: v => v.length < 40 || "max 40 characters allowed",
        event: v => v.length < 8 || "max 8 characters allowed",
        emailMatch: () => "The e-mail and password you entered don't match",
        email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
        }
    }
});

export default {
    state: initialState(),
    actions: {},
    mutations: {
        ResetConstant(state) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                state[key] = newState[key];
            });
        }
    },
    getters: {
        getRules: state => state.rules
    }
};
