import API from "@/services/api";
import http from "@/services/http";
import Vendor from "../models/Vendor";
import VendorSpecifications from "../models/VendorSpecifications";
import VendorSpace from "../models/VendorSpace";
import { getField, updateField } from "vuex-map-fields";

const initialState = () => ({
    newVendor: new Vendor(),
    vendor: new Vendor(),
    isVendorUpdate: false,
    activeVendorType: "hotel", // For Listing
    activeComponent: "home", // For Slider
    newVendorSpecifications: new VendorSpecifications(),
    vendorSpecifications: new VendorSpecifications(),
    newVendorSpace: new VendorSpace(),
    vendorSpace: new VendorSpace(),
    vendors: [],
    draftVendorsList: [],
    draftVendorsListStatus: 0,
    vendorSearch: [],
    vendorLoadStatus: 0,
    vendorsLoadStatus: 0,
    vendorAddStatus: 0,
    vendorUpdateStatus: 0,
    vendorDeleteStatus: 0,
    vendorSearchStatus: 0,
    vendorErrors: {},
    pendingListingCount: 0,
    offset: 0,
    page: 1,
    overlay: false
});

export default {
    state: initialState(),
    actions: {
        async latLngByAddress({ commit }, payload = {}) {
            let address = payload.address;
            address += payload.city ? " ," + payload.city : "";
            address += payload.country ? " ," + payload.country : "";
            let apiURL = encodeURI(
                `https://nominatim.openstreetmap.org/search.php?q=${address}&format=jsonv2&addressdetails=1`
            );
            let response = await fetch(apiURL);
            if (response.ok && response.status === 200) {
                response = await response.json();
                return response;
            }
            return false;
        },
        async loadAllDrafts({ commit }) {
            commit("setDraftVendorsList", 1);
            let response = await API.getDraftVendors("draftVendors");
            if (response.status === 200) {
                commit("setDraftVendorsList", 2);
                commit("setDraftVendorsList", response.data.data);
                commit("setPendingListing", response.data.drafts);
            } else {
                commit("setDraftVendorsList", 3);
            }
        },
        async loadVendors({ commit, state, dispatch }, payload = {}) {
            commit("setOverlay", true);
            if (state.page === 1) commit("setVendors", []);
            let response = await API.getList("Vendor", { ...{ page: state.page }, ...payload });
            if (response.status === 200) {
                let vendors = _.orderBy(response.data.data, ["created_at"], ["desc"]);
                commit("addVendorsToState", vendors);
                commit("setVendorsLoadStatus", 2);
                commit("setOverlay", false);
                if (!state.vendor.id && !!vendors.length > 0) {
                    commit("setActiveVendorType", vendors[0].type);
                    await dispatch("loadVendor", { id: vendors[0].id });
                } else commit("setVendorLoadStatus", 2);
                return vendors.length;
            } else {
                commit("setVendorsLoadStatus", 3);
                return -1;
            }
        },
        async loadVendor({ commit, state, rootState, getters }, payload = {}) {
            if (payload.hasOwnProperty("mlforceload") || payload.id !== getters.getActiveVendor) {
                if (!payload.hasOwnProperty("mlsilent")) commit("setVendorLoadStatus", 1);
                let model = "Hotel";
                if (state.activeVendorType === "location") {
                    model = "Location";
                }
                let response = await API.getOne(model, payload);
                if (response.status === 200) {
                    commit("setVendor", response.data.data);

                    commit("setVendorSpecifications", response.data.data.vendor_specifications);
                    if (state.activeVendorType === "location") {
                        commit("setVendorSpace", response.data.data.location_spaces);
                    } else {
                        commit("setVendorSpace", new VendorSpace());
                    }

                    commit("updateVendorList", response.data.data);
                    commit("setVendorLoadStatus", 2);
                } else {
                    commit("setVendorLoadStatus", 3);
                }
            }
        },
        async addVendor({ commit, state, dispatch }, payload = {}) {
            let vendor = "";
            if (state.newVendor.type === "location") {
                vendor = "Location";
            } else if (state.newVendor.type === "hotel") {
                vendor = "Hotel";
            }
            commit("setVendorAddStatus", 1);
            let response = await API.post(vendor, { ...state.newVendor, ...payload });
            if (response.status === 200 || response.status === 201) {
                commit("setIsVendorUpdate", false);
                dispatch("loadAllDrafts");
                commit("setVendorAddStatus", 2);
                commit("resetNewVendor");
                commit("resetVendorErrors");
                commit("updateVendorList", response.data.data);
                commit("setVendor", response.data.data);
                commit("setVendorLoadStatus", 2);
            } else if (response.status === 422) {
                commit("setVendorAddStatus", 3);
                commit("setVendorErrors", response.data.errors);
            } else {
                commit("setVendorAddStatus", 3);
                commit("setVendorErrors", {});
            }
        },
        async updateVendor({ commit, state, dispatch }, payload = {}) {
            let vendor = "";
            if (state.vendor.type === "location") {
                vendor = "Location";
            } else if (state.vendor.type === "hotel") {
                vendor = "Hotel";
            }
            commit("setVendorUpdateStatus", 1);
            let response = await API.put(vendor, { ...state.vendor, ...payload });
            if (response.status === 200 || response.status === 202) {
                commit("setVendorUpdateStatus", 2);
                commit("setIsVendorUpdate", false);
                dispatch("loadAllDrafts");
                commit("resetVendorErrors");
                commit("updateVendorList", response.data.data);
                commit("updateVendorStatus", response.data.data.status);
            } else if (response.status === 422) {
                commit("setVendorUpdateStatus", 3);
                commit("setVendorErrors", response.data.errors);
            } else {
                commit("setVendorUpdateStatus", 3);
                commit("setVendorErrors", {});
            }
        },
        async updateVendorStatus({ commit, state }, payload = {}) {
            commit("setVendorUpdateStatus", 1);
            let response = await API.put("Vendor", payload);
            if (response.status === 200 || response.status === 202) {
                commit("setVendorUpdateStatus", 2);
                commit("setIsVendorUpdate", false);
                commit("resetVendorErrors");
                commit("updateVendorList", response.data.data);
                commit("updateVendorStatus", response.data.data.status);
            } else if (response.status === 422) {
                commit("setVendorUpdateStatus", 3);
                commit("setVendorErrors", response.data.errors);
            } else {
                commit("setVendorUpdateStatus", 3);
                commit("setVendorErrors", {});
            }
        },
        async removeMedia({ commit, state }, payload = {}) {
            const response = await http.delete(`/locations/medias/${payload.id}`);
            if (response.status == 200) {
                commit("removeVendorMedia", response.data.image.id);
            } else {
            }
        }
    },
    mutations: {
        setOverlay(state, status) {
            state.overlay = status;
        },
        addVendorsToState(state, vendors) {
            _.forEach(vendors, function(v) {
                if (!state.vendors.some(o => o.id === v.id && o.type === v.type)) state.vendors.push(v);
            });
            state.page += 1;
        },
        resetVendorPagination(state) {
            state.page = 1;
        },
        setActiveVendorType(state, field) {
            state.activeVendorType = field;
        },
        removeVendorMedia(state, id) {
            const index = state.vendor.gallery.findIndex(item => item.id === id);
            if (index > -1) {
                state.vendor.gallery.splice(index, 1);
            }
        },
        setIsVendorUpdate(state, field) {
            state.isVendorUpdate = field;
        },
        setActiveComponent(state, field) {
            state.activeComponent = field;
        },
        setVendorFields(state, field) {
            updateField(state.vendor, field);
        },
        setNewVendorFields(state, field) {
            updateField(state.newVendor, field);
        },
        setVendorLoadStatus(state, status) {
            state.vendorLoadStatus = status;
        },
        setVendorsLoadStatus(state, status) {
            state.vendorsLoadStatus = status;
        },
        setVendorAddStatus(state, status) {
            state.vendorAddStatus = status;
        },
        setVendor(state, vendor) {
            state.vendor = vendor;
        },
        setVendorSpecifications(state, field) {
            state.vendorSpecifications = field;
        },
        setVendorSpace(state, field) {
            state.vendorSpace = field;
        },
        setNewVendor(state, vendor) {
            state.newVendor = vendor;
        },
        setVendorUpdateStatus(state, vendor) {
            state.vendorUpdateStatus = vendor;
        },
        setVendors(state, vendors) {
            state.vendors = vendors;
        },
        setVendorErrors(state, errors) {
            state.vendorErrors = errors;
        },
        setPendingListing(state, pendingListingCount) {
            state.pendingListingCount = pendingListingCount;
        },
        setVendorByIdAndType(state, payload) {
            state.vendor = state.vendors.find(o => o.id === payload.id && o.type === payload.type);
        },
        updateVendorStatus(state, status) {
            state.vendor.status = status;
        },
        updateVendorList(state, vendor = null) {
            const obj = vendor
                ? state.vendors.find(o => o.id === vendor.id && o.type === vendor.type)
                : state.vendors.find(o => o.id === state.vendor.id && o.type === state.vendor.type);
            if (obj) Object.assign(obj, vendor ? vendor : state.vendor);
            else state.vendors.unshift(vendor);
            if (vendor && state.vendor.id === vendor.id && state.vendor.type === vendor.type) {
                if (vendor && !!state.vendor.id) state.vendor = vendor;
            }
        },
        pushVendorMedia(state, images) {
            state.vendor.gallery = [...state.vendor.gallery, ...images];
        },
        setDraftVendorsList(state, draftVendors) {
            state.draftVendorsList = draftVendors;
        },
        resetNewVendor(state) {
            state.newVendor = new Vendor();
        },
        resetVendorErrors(state) {
            state.vendorErrors = {};
        },
        ResetVendor(state) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                state[key] = newState[key];
            });
        }
    },

    getters: {
        getActiveComponent: state => state.activeComponent,
        getIsVendorUpdate: state => state.isVendorUpdate,
        getActiveVendorType: state => state.activeVendorType,
        getNewVendorSpace: state => state.newVendorSpace,
        getVendorFields: state => getField(state.vendor),
        getNewVendorFields: state => getField(state.newVendor),
        getNewVendor: state => state.newVendor,
        getNewVendorSpecification: state => state.newVendorSpecifications,
        getVendorSpecification: state => state.vendorSpecifications,
        getVendorSpace: state => state.vendorSpace,
        getVendor: state => state.vendor,
        getVendors: state => state.vendors,
        getActiveVendor: state => (!!state.vendor.id ? state.vendor.id : null),
        getVendorErrors: state => state.vendorErrors,
        getVendorLoadStatus: state => state.vendorLoadStatus,
        getVendorsLoadStatus: state => state.vendorsLoadStatus,
        getVendorAddStatus: state => state.vendorAddStatus,
        getVendorUpdateStatus: state => state.vendorUpdateStatus,
        getVendorDeleteStatus: state => state.vendorDeleteStatus,
        getVendorsByOrgId: state => organizationId =>
            state.vendors.filter(vendor => vendor.organization_id === organizationId),
        getPendingListing: state => state.pendingListingCount,
        getOffset: state => state.offset,
        getOverlay: state => state.overlay,
        getAllDraftVendorsList: state => orgId =>
            _.filter(state.draftVendorsList, vendor => vendor.organization_id === orgId)
    }
};
