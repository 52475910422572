const initialState = () => ({
    loaderActive: false,
    toggleSlider: false,
    overlayActive: false,
    listType: 'hotel',
});

export default {
    state: initialState(),
    actions: { },
    mutations: {
        setLoader ( state , status ) { state.loaderActive = status; },
        setListType ( state , status ) { state.listType = status; },
        setToggleSlider ( state , status ) { state.toggleSlider = status; },
        setOverlay ( state , status ) { state.overlayActive = status; },
        toggleLoader( state ) { state.loaderActive = !state.loaderActive; },
        toggleOverlay( state ) { state.overlayActive = !state.overlayActive; },
        ResetGlobal(state) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                state[key] = newState[key]
            });
        },
    },
    getters: {
        getLoaderStatus: state => state.loaderActive,
        getListType: state => state.listType,
        getToggleSlider: state => state.toggleSlider,
        getOverlayStatus: state => state.overlayActive,
    }
}
