export default class Auth {
    constructor({
        id = null,
        organization_id = null,
        name = null,
        email = null,
        phone = null,
        user_type = null,
        user_role = null,
        status = null,
    } = {}) {
        this.id = id;
        this.organization_id = organization_id;
        this.name = name;
        this.email = email;
        this.phone = phone;
        this.user_type = user_type;
        this.user_role = user_role;
        this.status = status;
    }
}