import API from '@/services/api';
import Hotel from "../models/Hotel";
import HotelSpecifications from "../models/HotelSpecifications";
import { getField, updateField } from 'vuex-map-fields';

const initialState = () => ({
    newHotel: new Hotel(),
    hotel: new Hotel(),
    newHotelSpecifications: new HotelSpecifications(),
    hotelSpecifications: new HotelSpecifications(),
    hotels: [],
    hotelSearch: [],
    hotelLoadStatus: 0,
    hotelsLoadStatus: 0,
    hotelAddStatus: 0,
    hotelUpdateStatus: 0,
    hotelDeleteStatus: 0,
    hotelSearchStatus: 0,
    hotelErrors: {},
});

export default {
    state: initialState(),
    actions: {
        async loadHotels({ commit, state, dispatch }, payload = {} ) {
                let response = await API.getList('Hotel', payload);
                if(response.status === 200)
                {
                    commit( 'setHotels', response.data.data );
                    commit( 'setHotelsLoadStatus', 2 );
                    if(!state.hotel.id && !!response.data.data.length > 0) {
                        await dispatch('loadHotel', { id: response.data.data[0].id });
                    } else commit( 'setHotelLoadStatus', 2 );
                } else {
                    commit( 'setHotelsLoadStatus', 3 );
                }
        },
        async loadHotel({ commit, state, rootState, getters }, payload = {} ) {
            if(payload.hasOwnProperty('mlforceload') || payload.id !== getters.getActiveHotel ) {
                if(!payload.hasOwnProperty('mlsilent')) commit( 'setHotelLoadStatus', 1 );
                let response = await API.getOne('Hotel', payload);
                if(response.status === 200) {
                    commit( 'setHotel', response.data.data );
                    commit( 'updateHotelList', response.data.data );
                    commit( 'setHotelLoadStatus', 2 );
                } else {
                    commit( 'setHotelLoadStatus', 3 );
                }
            }
        },
        async addHotel({ commit, state }, payload = {} ) {
            commit( 'setHotelAddStatus', 1 );
            let response = await API.post( 'Hotel', { ...state.newHotel, ...payload } );
            if(response.status === 200){
                commit( 'setHotelAddStatus', 2 );
                commit( 'resetNewHotel' );
                commit( 'resetHotelErrors' );
                commit( 'updateHotelList', response.data.data );
                commit( 'setHotel', response.data.data );
                commit( 'setHotelLoadStatus', 2 );
            } else if(response.status === 422){
                commit( 'setHotelAddStatus', 3 );
                commit( 'setHotelErrors', response.data.errors );
            } else {
                commit( 'setHotelAddStatus', 3 );
                commit( 'setHotelErrors', {} );
            }
        },
        async updateHotel({ commit, state }, payload = {} ) {
            commit( 'setHotelUpdateStatus', 1 );
            let response = await API.put( 'Hotel', { ...state.hotel, ...payload } );
            if(response.status === 200 || response.status === 202) {
                commit( 'setHotelUpdateStatus', 2 );
                commit( 'resetHotelErrors' );
                commit( 'updateHotelList' );
            } else if(response.status === 422) {
                commit( 'setHotelUpdateStatus', 3 );
                commit( 'setHotelErrors', response.data.errors );
            } else {
                commit( 'setHotelUpdateStatus', 3 );
                commit( 'setHotelErrors', {} );
            }
        },
    },
    mutations: {
        setHotelFields( state, field ) { updateField(state.hotel, field); },
        setNewHotelFields( state, field ) { updateField(state.newHotel, field); },
        setHotelLoadStatus( state, status ) { state.hotelLoadStatus = status; },
        setHotelsLoadStatus( state, status ) { state.hotelsLoadStatus = status; },
        setHotelAddStatus( state, status ) { state.hotelAddStatus = status; },
        setHotel( state, hotel ) { state.hotel = hotel; },
        setNewHotel( state, hotel ) { state.newHotel = hotel; },
        setHotelUpdateStatus( state, hotel ) { state.hotelUpdateStatus = hotel; },
        setHotels( state, hotels ) { state.hotels = hotels; },
        mergeLocationsToHotels( state, locations ) {
            let hotels = state.hotels;
            _.forEach(locations, (location) => { hotels.push(location); });
            state.hotels = hotels;
        },
        setHotelErrors( state, errors ) { state.hotelErrors = errors; },
        updateHotelListStatus( state, hotel = null ) {
            const obj = state.hotels.find(o => o.id === hotel.hotel_id);
            let updatedObj = obj;
            updatedObj.status = hotel.status;
            if(obj) Object.assign( obj, updatedObj);
        },
        updateHotelList( state, hotel = null ) {
            const obj = hotel ? state.hotels.find(o => o.id === hotel.id) : state.hotels.find(o => o.id === state.hotel.id);
            if(obj) Object.assign( obj, hotel ? hotel : state.hotel );
            else state.hotels.push(hotel);
            if(hotel && !!state.hotel.id) state.hotel = hotel;
        },
        resetNewHotel(state) { state.newHotel = new Hotel() },
        resetHotelErrors(state) { state.hotelErrors = {} },
        ResetHotel(state) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                state[key] = newState[key]
            });
        },
    },

    getters: {
        getHotelFields: state => getField(state.hotel),
        getNewHotelFields: state => getField(state.newHotel),
        getNewHotel: state => state.newHotel,
        getNewHotelSpecification: state => state.newHotelSpecifications,
        getHotelSpecification: state => state.hotelSpecifications,
        getHotel: state => state.hotel,
        getHotels: state => state.hotels,
        getActiveHotel: state => !!state.hotel.id ? state.hotel.id : null,
        getHotelErrors: state => state.hotelErrors,
        getHotelLoadStatus: state => state.hotelLoadStatus,
        getHotelsLoadStatus: state => state.hotelsLoadStatus,
        getHotelAddStatus: state => state.hotelAddStatus,
        getHotelUpdateStatus: state => state.hotelUpdateStatus,
        getHotelDeleteStatus: state => state.hotelDeleteStatus,
        getHotelsByOrgId: state => organizationId => state.hotels.filter( hotel => hotel.organization_id === organizationId ),
    }
}
