import API from "@/services/api";
import router from "@/router";
import Auth from "../models/Auth";

const initialState = () => ({
    authStatus: 0,
    signupStatus: 0,
    confirmStatus: 0,
    forgotStatus: 0,
    authErrors: {},
    verifyErrors: {},
    forgotMessage: {},
    forgotErrors: {},
    authUser: JSON.parse(localStorage.getItem("mluser")) || new Auth(),
    token: localStorage.getItem("mltoken") || null
});

export default {
    state: initialState(),
    actions: {
        async login({ commit, dispatch }, payload = {}) {
            commit("setAuthStatus", 1);
            let response = await API.login(payload);
            if (response.status === 200) {
                commit("setAuthStatus", 2);
                commit("setAuth", response.data.data);
                await dispatch("loadAccounts");
                router.push({ name: "hotels" });
            } else if (response.status === 422) {
                commit("setAuthStatus", 3);
                commit("setAuthErrors", response.data.errors);
            }
        },

        async forgotPassword({ commit, dispatch }, payload = {}) {
            commit("setForgotStatus", 1);
            commit("setForgotMessage", {});
            commit("setForgotErrors", {});
            let response = await API.forgotPassword(payload);
            if (response.status === 200) {
                commit("setForgotMessage", { message: [response.data.message] });
                commit("setForgotStatus", 2);
            } else {
                commit("setForgotStatus", 3);
                commit("setForgotErrors", response.data.errors);
            }
        },

        async logout({ commit, dispatch }, payload = {}) {
            commit("setAuthStatus", 1);
            let response = await API.logout(payload);
            if (response.status === 200) {
                commit("setAuthStatus", 2);
                commit("ResetAuth");
                await dispatch("ResetAll");
            } else {
                commit("setAuthStatus", 3);
            }
        },

        async confirmPassword({ commit }, payload = {}) {
            commit("setConfirmStatus", 1);
            let response = await API.confirmPassword(payload);
            if (response.status === 202) {
                commit("setConfirmStatus", 2);
                return true;
            } else if (response.status === 422) {
                commit("setConfirmStatus", 3);
                commit("setVerifyErrors", response.data.errors);
                return false;
            }
        },
        async signup({ commit }, payload = {}) {
            commit("setSignupStatus", 1);
            let response = await API.signup(payload);
            if (response.status === 200) {
                commit("setSignupStatus", 2);
            } else if (response.status === 422) {
                commit("setSignupStatus", 3);
                commit("setAuthErrors", response.data.errors);
            }
        }
    },
    mutations: {
        setAuthStatus(state, status) {
            state.authStatus = status;
        },
        setSignupStatus(state, status) {
            state.signupStatus = status;
        },
        setConfirmStatus(state, status) {
            state.confirmStatus = status;
        },
        setForgotStatus(state, status) {
            state.forgotStatus = status;
        },
        setAuthErrors(state, errors) {
            state.authErrors = errors;
        },
        setVerifyErrors(state, errors) {
            state.verifyErrors = errors;
        },
        updateAuth(state, data) {
            state.authUser.name = data.first_name + " " + data.last_name;
            state.authUser.first_name = data.first_name;
            state.authUser.last_name = data.last_name;
            state.authUser.job_title = data.job_title;
            state.authUser.phone = data.phone;
            localStorage.setItem("mluser", JSON.stringify(state.authUser));
        },
        setAuth(state, data) {
            state.authUser = data;
            state.token = data.token;
            localStorage.setItem("mluser", JSON.stringify(data));
            localStorage.setItem("mltoken", data.token);
            localStorage.setItem("expires_at", data.token_expires_at);
        },
        setForgotMessage(state, forgotMessage) {
            state.forgotMessage = forgotMessage;
        },
        setForgotErrors(state, forgotErrors) {
            state.forgotErrors = forgotErrors;
        },
        ResetAuth(state) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                state[key] = newState[key];
            });
        }
    },

    getters: {
        isAuthenticated: state => !!state.authUser.id,
        getAuthErrors: state => state.authErrors,
        getVerifyErrors: state => state.verifyErrors,
        getAuthStatus: state => state.authStatus,
        getSignupStatus: state => state.signupStatus,
        getForgotStatus: state => state.forgotStatus,
        getConfirmStatus: state => state.confirmStatus,
        getAuthToken: state => state.token,
        getAuthUser: state => state.authUser,
        getForgotMessage: state => state.forgotMessage,
        getForgotErrors: state => state.forgotErrors,
        isAdmin: state => state.authUser.user_type === "admin"
    }
};
