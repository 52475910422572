export default class Message {
    constructor({
        id = null,
        conversation_id = null,
        user_id = null,
        body = null,
        timestamp = null,
        is_system_message = false,
    } = {}) {
        this.id = id;
        this.conversation_id = conversation_id;
        this.user_id = user_id;
        this.body = body;
        this.timestamp = timestamp;
        this.is_system_message = is_system_message;
    }
}