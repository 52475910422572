export default class LocationSpace {
    constructor({
        id = null,
        location_id = null,
        space_height_in_meters = null,
        space_type = null,
        size = null,
        floor_condition = null,
        max_occ_seating = null,
        max_occ_standing = null,
        is_daylight = null,
        status = null,
    } = {}) {
        this.id = id;
        this.location_id = location_id,
        this.space_height_in_meters = space_height_in_meters,
        this.space_type = space_type,
        this.size = size,
        this.floor_condition = floor_condition,
        this.max_occ_seating = max_occ_seating,
        this.max_occ_standing = max_occ_standing,
        this.is_daylight = is_daylight,
        this.status = status;
    }
}