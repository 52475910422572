export default class VendorSpace {
    constructor({
        id = null,
        vendorable_id = null,
        vendorable_type = null,

        category = null,
        name = null,
        description = null,
        acoustic_description = null,
        floor_number = null,
        floor_type = null,
        is_outdoor = null,
        is_daylight = null,
        window_length = null,
        window_width = null,
        has_parking_space = null,
        parking_slots = null,
        length = null,
        width = null,
        height = null,
        size = null,
        is_standing_capacity = null,
        standing_max_capacity = null,
        is_u_shape = null,
        u_shape_max_capacity = null,
        is_banquet_rounds = null,
        banquet_rounds_max_capacity = null,
        is_cocktail_rounds = null,
        cocktail_rounds_max_capacity = null,
        is_theater = null,
        theater_max_capacity = null,
        is_classroom = null,
        classroom_max_capacity = null,
        is_boardroom = null,
        boardroom_max_capacity = null,
        size_type = "measurements",
        amenities = [],

        removable = true,
        status = null,
    } = {}) {
        this.id = id;
        this.vendorable_id = vendorable_id;
        this.vendorable_type = vendorable_type;

        this.category = category;
        this.name = name;
        this.description = description;
        this.acoustic_description = acoustic_description;
        this.floor_number = floor_number;
        this.floor_type = floor_type;
        this.is_outdoor = is_outdoor;
        this.is_daylight = is_daylight;
        this.window_width = window_width;
        this.window_length = window_length;
        this.has_parking_space = has_parking_space;
        this.parking_slots = parking_slots;
        this.length = length;
        this.width = width;
        this.height = height;
        this.size = size;
        this.is_standing_capacity = is_standing_capacity;
        this.standing_max_capacity = standing_max_capacity;
        this.is_u_shape = is_u_shape;
        this.u_shape_max_capacity = u_shape_max_capacity;
        this.is_banquet_rounds = is_banquet_rounds;
        this.banquet_rounds_max_capacity = banquet_rounds_max_capacity;
        this.is_cocktail_rounds = is_cocktail_rounds;
        this.cocktail_rounds_max_capacity = cocktail_rounds_max_capacity;
        this.is_theater = is_theater;
        this.theater_max_capacity = theater_max_capacity;
        this.is_classroom = is_classroom;
        this.classroom_max_capacity = classroom_max_capacity;
        this.is_boardroom = is_boardroom;
        this.boardroom_max_capacity = boardroom_max_capacity;
        this.amenities = amenities;
        (this.size_type = size_type), (this.removable = removable);
        this.status = status;
    }
}
