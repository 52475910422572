export default class Account {
    constructor({
        id = null,
        name = null,
        account_type = null,
        is_demo = null,
        status = null,
        created_at = null,
        updated_at = null,
        deleted_at = null,
    } = {}) {
        this.id = id;
        this.name = name;
        this.account_type = account_type;
        this.is_demo = is_demo;
        this.status = status;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.deleted_at = deleted_at;
    }
}