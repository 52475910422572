export default class HotelSpecifications {
    constructor({
        id = null,
        hotel_id = null,
        is_reception_always_available = true,
        has_event_space = true,
        has_meeting_space = true,
        has_activities = true,
        is_restaurant = null,
        is_bar = null,
        total_meeting_spaces = null,
        total_parking_spaces = null,
        status = null,
    } = {}) {
        this.id = id;
        this.hotel_id = hotel_id;
        this.is_reception_always_available = is_reception_always_available;
        this.has_event_space = has_event_space;
        this.has_meeting_space = has_meeting_space;
        this.has_activities = has_activities;
        this.is_restaurant = is_restaurant;
        this.is_bar = is_bar;
        this.total_meeting_spaces = total_meeting_spaces;
        this.total_parking_spaces = total_parking_spaces;
        this.status = status;
    }
}