import API from '@/services/api';
import Request from "../models/Request";
import { getField, updateField } from 'vuex-map-fields';

const initialState = () => ({
    newRequest: new Request(),
    request: new Request(),
    requests: [],
    parentChildRequests: [],
    requestSearch: [],
    requestLoadStatus: 0,
    requestsLoadStatus: 0,
    requestAddStatus: 0,
    requestUpdateStatus: 0,
    requestDeleteStatus: 0,
    requestSearchStatus: 0,
    requestErrors: {},
});

export default {
    state: initialState(),
    actions: {
        async loadRequests({ commit, state, dispatch }, payload = {} ) {
                let response = await API.getList('Request', payload);
                if(response.status === 200)
                {
                    commit( 'setRequests', response.data.data );
                    commit( 'setRequestsLoadStatus', 2 );
                    if(!state.request.id && !!response.data.data.length > 0) {
                        await dispatch('loadRequest', { id: response.data.data[0].id });
                    } else commit( 'setRequestLoadStatus', 2 );
                } else {
                    commit( 'setRequestsLoadStatus', 3 );
                }
        },
        async loadRequest({ commit, state, rootState, getters }, payload = {} ) {
            if(payload.hasOwnProperty('mlforceload') || payload.id !== getters.getActiveRequest ) {
                if(!payload.hasOwnProperty('mlsilent')) commit( 'setRequestLoadStatus', 1 );
                let response = await API.getOne('Request', payload);
                if(response.status === 200) {
                    commit( 'setRequest', response.data.data );
                    commit( 'setConversation', response.data.data.conversation);
                    commit( 'updateRequestList', response.data.data );
                    commit( 'setRequestLoadStatus', 2 );
                } else {
                    commit( 'setRequestLoadStatus', 3 );
                }
            }
        },
        async addRequest({ commit, state }, payload = {} ) {
            commit( 'setRequestAddStatus', 1 );
            let response = await API.post( 'Request', { ...state.newRequest, ...payload } );
            if(response.status === 200){
                commit( 'setRequestAddStatus', 2 );
                commit( 'resetNewRequest' );
                commit( 'resetRequestErrors' );
                commit( 'setConversation', response.data.data.conversation);
                commit( 'updateRequestList', response.data.data );
                commit( 'setRequest', response.data.data );
                commit( 'setRequestLoadStatus', 2 );
            } else if(response.status === 422){
                commit( 'setRequestAddStatus', 3 );
                commit( 'setRequestErrors', response.data.errors );
            } else {
                commit( 'setRequestAddStatus', 3 );
                commit( 'setRequestErrors', {} );
            }
        },
        async updateRequest({ commit, state }, payload = {} ) {
            commit( 'setRequestUpdateStatus', 1 );
            let response = await API.put( 'Request', { ...state.request, ...payload } );
            if(response.status === 200 || response.status === 202) {
                commit( 'setRequestUpdateStatus', 2 );
                commit( 'setConversation', response.data.data.conversation);
                commit( 'resetRequestErrors' );
                commit( 'updateRequestList' );
            } else if(response.status === 422) {
                commit( 'setRequestUpdateStatus', 3 );
                commit( 'setRequestErrors', response.data.errors );
            } else {
                commit( 'setRequestUpdateStatus', 3 );
                commit( 'setRequestErrors', {} );
            }
        },
    },
    mutations: {
        setRequestFields( state, field ) { updateField(state.request, field); },
        setNewRequestFields( state, field ) { updateField(state.newRequest, field); },
        setRequestLoadStatus( state, status ) { state.requestLoadStatus = status; },
        setRequestsLoadStatus( state, status ) { state.requestsLoadStatus = status; },
        setRequestAddStatus( state, status ) { state.requestAddStatus = status; },
        setRequest( state, request ) { state.request = request; },
        setNewRequest( state, request ) { state.newRequest = request; },
        setRequestUpdateStatus( state, request ) { state.requestUpdateStatus = request; },
        setRequests( state, requests ) { state.requests = requests; },
        mergeLocationsToRequests( state, locations ) {
            let requests = state.requests;
            _.forEach(locations, (location) => { requests.push(location); });
            state.requests = requests;
        },
        setRequestErrors( state, errors ) { state.requestErrors = errors; },
        updateRequestListStatus( state, request = null ) {
            const obj = state.requests.find(o => o.id === request.id);
            let updatedObj = obj;
            updatedObj.status = request.status;
            if(obj) Object.assign( obj, updatedObj);
        },
        updateRequestListUpdatedAt(state, request = {}) {
            const obj = state.requests.find(o => o.id === request.id);
            let updatedObj = obj;
            updatedObj.updated_at = request.updated_at;
            if (obj) Object.assign(obj, updatedObj);
        },
        updateRequestList( state, request = null ) {
            const obj = request ? state.requests.find(o => o.id === request.id) : state.requests.find(o => o.id === state.request.id);
            if(obj) Object.assign( obj, request ? request : state.request );
            else state.requests.push(request);
            if(request && !!state.request.id) state.request = request;
        },
        resetNewRequest(state) { state.newRequest = new Request() },
        resetRequestErrors(state) { state.requestErrors = {} },
        ResetRequest(state) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                state[key] = newState[key]
            });
        },
        updateConversationMessage(state, message) {
            if (state.request.id === message.request_id) { state.request.conversation.push(message); }
            else {
                const obj = state.requests.find(o => o.id === message.request_id);
                if (obj) { let request = obj; request.unread_count = obj.unread_count + 1; Object.assign(obj, request); }
            }
        },
    },

    getters: {
        getRequestFields: state => getField(state.request),
        getNewRequestFields: state => getField(state.newRequest),
        getNewRequest: state => state.newRequest,
        getRequest: state => state.request,
        getRequests: state => state.requests,
        getActiveRequest: state => !!state.request.id ? state.request.id : null,
        getRequestErrors: state => state.requestErrors,
        getRequestLoadStatus: state => state.requestLoadStatus,
        getRequestsLoadStatus: state => state.requestsLoadStatus,
        getRequestAddStatus: state => state.requestAddStatus,
        getRequestUpdateStatus: state => state.requestUpdateStatus,
        getRequestDeleteStatus: state => state.requestDeleteStatus,
    }
}
