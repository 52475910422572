export default class LocationSpecifications {
    constructor({
        id = null,
        location_id = null,
        has_high_power_connection = null,
        has_parking = null,
        total_parking_spaces = null,
        has_activities = null,
        total_breakout_rooms = null,
        status = null,
    } = {}) {
        this.id = id;
        this.location_id = location_id;
        this.has_high_power_connection = has_high_power_connection;
        this.has_parking = has_parking;
        this.total_parking_spaces = total_parking_spaces;
        this.has_activities = has_activities;
        this.total_breakout_rooms = total_breakout_rooms;
        this.status = status;
    }
}