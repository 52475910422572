export default class Coupon {
    constructor({
        id = null,
        name = null,
        code= null,
        discount= null,
        validity = null,
        redemptions= null,
        is_enabled = null,
        is_default = 0,
        is_demo = null,
        status = null,
        created_at = null,
        updated_at = null,
        deleted_at = null,
        } = {}) {
        this.id = id;
        this.name = name;
        this.code = code;
        this.discount = discount;
        this.validity = validity;
        this.redemptions = redemptions;
        this.is_enabled = is_enabled;
        this.is_default = is_default;
        this.is_demo = is_demo;
        this.status = status;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.deleted_at = deleted_at;
    }
}