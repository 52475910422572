import API from "@/services/api";
import Subscription from "../models/Subscription";
import { getField, updateField } from "vuex-map-fields";

const initialState = () => ({
    newSubscription: new Subscription(),
    subscription: new Subscription(),
    subscriptions: [],
    cachedSubscriptions: [],
    paginationMeta: {
        current_page: 1,
        last_page: 1,
        per_page: 10,
        total: 0,
        from: 0,
        to: 0,
    },
    subscriptionLoadStatus: 0,
    subscriptionsLoadStatus: 0,
    subscriptionAddStatus: 0,
    subscriptionUpdateStatus: 0,
    subscriptionDeleteStatus: 0,
    subscriptionErrors: {},
    lastLoadRequestTime: Date.now(),
});

export default {
    state: initialState(),
    actions: {
        async loadSubscriptions({ commit, state, dispatch }, payload = {}) {
            if (Date.now() - state.lastLoadRequestTime < 300) {
                return;
            }
            commit("setLastLoadRequestTime", Date.now());
            const { page } = payload;

            // if (
            //     !payload.query &&
            //     state.paginationMeta.per_page == payload.itemsPerPage &&
            //     page in state.cachedSubscriptions
            // ) {
            //     commit("setSubscriptions", state.cachedSubscriptions[page]);
            //     return;
            // }

            commit("setSubscriptionsLoadStatus", 1);
            let response = await API.getList("Subscription", payload);
            if (response.status === 200) {
                commit("setSubscriptions", response.data.data);
                commit("setPaginationMeta", response.data.meta);
                //commit("cacheSubscriptions", { subscriptions: response.data.data, page });
                commit("setSubscriptionsLoadStatus", 2);
                if (!state.subscription.id && !!response.data.data.length > 0) {
                    await dispatch("loadSubscription", { id: response.data.data[0].id });
                } else commit("setSubscriptionLoadStatus", 2);
            } else {
                commit("setSubscriptionsLoadStatus", 3);
            }
        },
        async loadSubscription({ commit, state, rootState, getters }, payload = {}) {
            if (payload.hasOwnProperty("mlforceload") || payload.id !== getters.getActiveSubscription) {
                if (!payload.hasOwnProperty("mlsilent")) commit("setSubscriptionLoadStatus", 1);
                let response = await API.getOne("Subscription", payload);
                if (response.status === 200) {
                    commit("setSubscription", response.data.data);
                    commit("updateSubscriptionList", response.data.data);
                    commit("setSubscriptionLoadStatus", 2);
                } else {
                    commit("setSubscriptionLoadStatus", 3);
                }
            }
        },
        async addSubscription({ commit, state, dispatch }, payload = {}) {
            commit("setSubscriptionAddStatus", 1);
            let response = await API.post("Subscription", { ...state.newSubscription, ...payload });
            if (response.status === 201 || response.status === 200) {
                commit("setSubscriptionAddStatus", 2);
                commit("resetNewSubscription");
                commit("resetSubscriptionErrors");
                commit("updateSubscriptionList", response.data.data);
                commit("setSubscription", response.data.data);
                commit("setSubscriptionLoadStatus", 2);
                await dispatch("loadVendors");
                await dispatch("loadAllDrafts");
            } else if (response.status === 422) {
                commit("setSubscriptionAddStatus", 3);
                commit("setSubscriptionErrors", response.data.errors);
            } else {
                commit("setSubscriptionAddStatus", 3);
                commit("setSubscriptionErrors", {});
            }
        },
        async updateSubscription({ commit, state }, payload = {}) {
            commit("setSubscriptionUpdateStatus", 1);
            let response = await API.put("Subscription", { ...state.subscription, ...payload });
            if (response.status === 200 || response.status === 202) {
                commit("setSubscriptionUpdateStatus", 2);
                commit("resetSubscriptionErrors");
                commit("updateSubscriptionList");
            } else if (response.status === 422) {
                commit("setSubscriptionUpdateStatus", 3);
                commit("setSubscriptionErrors", response.data.errors);
            } else {
                commit("setSubscriptionUpdateStatus", 3);
                commit("setSubscriptionErrors", {});
            }
        },
        async deleteSubscription({ commit, state }, payload = {}) {
            commit("setSubscriptionDeleteStatus", 1);
            let response = await API.delete("Subscription", payload);
            if (response.status === 200) {
                commit("setSubscriptionDeleteStatus", 2);
                commit("removeSubscription", payload);
            } else if (response.status === 444) {
                commit("setSubscriptionDeleteStatus", 3);
                commit("setSubscriptionErrors", { error: response.data.message });
            }
        },
    },
    mutations: {
        setLastLoadRequestTime(state, time) {
            state.lastLoadRequestTime = time;
        },
        setSubscriptionFields(state, field) {
            updateField(state.subscription, field);
        },
        setNewSubscriptionFields(state, field) {
            updateField(state.newSubscription, field);
        },
        setSubscriptionLoadStatus(state, status) {
            state.subscriptionLoadStatus = status;
        },
        setSubscriptionsLoadStatus(state, status) {
            state.subscriptionsLoadStatus = status;
        },
        setSubscriptionAddStatus(state, status) {
            state.subscriptionAddStatus = status;
        },
        setSubscription(state, subscription) {
            state.subscription = subscription;
        },
        setNewSubscription(state, subscription) {
            state.newSubscription = subscription;
        },
        setSubscriptionUpdateStatus(state, subscription) {
            state.subscriptionUpdateStatus = subscription;
        },
        setSubscriptions(state, subscriptions) {
            state.subscriptions = subscriptions;
        },

        cacheSubscriptions(state, { page, subscriptions }) {
            state.cachedSubscriptions[page] = subscriptions;
        },

        setPaginationMeta(state, meta) {
            state.paginationMeta = meta;
        },

        setSubscriptionDeleteStatus(state, status) {
            state.subscriptionDeleteStatus = status;
        },
        setSubscriptionErrors(state, errors) {
            state.subscriptionErrors = errors;
        },
        setSubscriptionById(state, id) {
            state.subscription = state.subscriptions.find((s) => s.id === id);
        },
        updateSubscriptionListStatus(state, subscription = null) {
            const obj = state.subscriptions.find((o) => o.id === subscription.subscription_id);
            let updatedObj = obj;
            updatedObj.status = subscription.status;
            if (obj) Object.assign(obj, updatedObj);
        },
        updateSubscriptionList(state, subscription = null) {
            const obj = subscription
                ? state.subscriptions.find((o) => o.id === subscription.id)
                : state.subscriptions.find((o) => o.id === state.subscription.id);
            if (obj) Object.assign(obj, subscription ? subscription : state.subscription);
            else state.subscriptions.push(subscription);
            if (subscription && !!state.subscription.id) state.subscription = subscription;
        },
        resetNewSubscription(state) {
            state.newSubscription = new Subscription();
        },
        resetSubscriptionErrors(state) {
            state.subscriptionErrors = {};
        },
        ResetSubscription(state) {
            const newState = initialState();
            Object.keys(newState).forEach((key) => {
                state[key] = newState[key];
            });
        },
    },

    getters: {
        getSubscriptionFields: (state) => getField(state.subscription),
        getNewSubscriptionFields: (state) => getField(state.newSubscription),
        getNewSubscription: (state) => state.newSubscription,
        getSubscription: (state) => state.subscription,
        getSubscriptions: (state) => state.subscriptions,
        getPaginationMeta: (state) => state.paginationMeta,
        getActiveSubscription: (state) => (!!state.subscription.id ? state.subscription.id : null),
        getSubscriptionErrors: (state) => state.subscriptionErrors,
        getSubscriptionLoadStatus: (state) => state.subscriptionLoadStatus,
        getSubscriptionsLoadStatus: (state) => state.subscriptionsLoadStatus,
        getSubscriptionAddStatus: (state) => state.subscriptionAddStatus,
        getSubscriptionUpdateStatus: (state) => state.subscriptionUpdateStatus,
        getSubscriptionDeleteStatus: (state) => state.subscriptionDeleteStatus,
    },
};
