import API from '@/services/api';
import Invoice from "../models/Invoice";
import {getField, updateField} from 'vuex-map-fields';

const initialState = () => ({
    newInvoice: new Invoice(),
    invoice: new Invoice(),
    invoices: [],
    invoiceLoadStatus: 0,
    invoicesLoadStatus: 0,
    invoiceAddStatus: 0,
    invoiceUpdateStatus: 0,
    invoiceDeleteStatus: 0,
    invoiceErrors: {},
    pdfUrl: null,
    stripeSessionId: null,
    pdfFile_name: null,
});

export default {
    state: initialState(),
    actions: {
        async loadInvoices({ commit, state, dispatch }, payload = {} ) {
            commit( 'setInvoicesLoadStatus', 1 );
            let response = await API.getList('Invoice', payload);
            if(response.status === 200)
            {
                commit( 'setInvoices', response.data.data );
                commit( 'setInvoicesLoadStatus', 2 );
                if(!state.invoice.id && !!response.data.data.length > 0) {
                    await dispatch('loadInvoice', { id: response.data.data[0].id });
                } else commit( 'setInvoiceLoadStatus', 2 );
            } else {
                commit( 'setInvoicesLoadStatus', 3 );
            }
        },
        async loadInvoice({ commit, state, rootState, getters }, payload = {} ) {
            if(payload.hasOwnProperty('mlforceload') || payload.id !== getters.getActiveInvoice ) {
                if(!payload.hasOwnProperty('mlsilent')) commit( 'setInvoiceLoadStatus', 1 );
                let response = await API.getOne('Invoice', payload);
                if(response.status === 200) {
                    commit( 'setInvoice', response.data.data );
                    commit( 'updateInvoiceList', response.data.data );
                    commit( 'setInvoiceLoadStatus', 2 );
                } else {
                    commit( 'setInvoiceLoadStatus', 3 );
                }
            }
        },
        async addInvoice({ commit, state }, payload = {} ) {
            commit( 'setInvoiceAddStatus', 1 );
            let response = await API.post( 'Invoice', { ...state.newInvoice, ...payload } );
            if(response.status === 200){
                commit( 'setInvoiceAddStatus', 2 );
                commit( 'resetNewInvoice' );
                commit( 'resetInvoiceErrors' );
                commit( 'updateInvoiceList', response.data.data );
                commit( 'setInvoice', response.data.data );
                commit( 'setInvoiceLoadStatus', 2 );
            } else if(response.status === 422){
                commit( 'setInvoiceAddStatus', 3 );
                commit( 'setInvoiceErrors', response.data.errors );
            } else {
                commit( 'setInvoiceAddStatus', 3 );
                commit( 'setInvoiceErrors', {} );
            }
        },
        async updateInvoice({ commit, state }, payload = {} ) {
            commit( 'setInvoiceUpdateStatus', 1 );
            let response = await API.put( 'Invoice', { ...state.invoice, ...payload } );
            if(response.status === 200 || response.status === 202) {
                commit( 'setInvoiceUpdateStatus', 2 );
                commit( 'resetInvoiceErrors' );
                commit( 'updateInvoiceList' );
            } else if(response.status === 422) {
                commit( 'setInvoiceUpdateStatus', 3 );
                commit( 'setInvoiceErrors', response.data.errors );
            } else {
                commit( 'setInvoiceUpdateStatus', 3 );
                commit( 'setInvoiceErrors', {} );
            }
        },
        async deleteInvoice({ commit, state }, payload = {} ) {
            commit('setInvoiceDeleteStatus',1);
            let response = await API.delete( 'Invoice', payload );
            if(response.status === 200) {
                commit('setInvoiceDeleteStatus',2);
                commit('removeInvoice', payload);
            }else if(response.status === 444){
                commit('setInvoiceDeleteStatus',3);
                commit( 'setInvoiceErrors', {'error':response.data.message} );
            }
        },
        async downloadInvoicePdf({commit, state}, payload={}){
            let response = await API.getPdf( 'proformaPdf', payload );
            if (response.status === 200){
                let url =   process.env.VUE_APP_URL + response.data.path;
                let file_name = response.data.file_name;
                commit('setPdfUrl',url);
                commit('setPdfFileName', file_name);
                await commit('downloadInvoicePdf');
            }
        },
        async createSessionByInvoiceId({ commit, state, dispatch }, payload = {} ) {
            let response = await API.post('CreateSession', payload);
            if(response.status === 200)
            {
                commit('setStripeSessionId', response.data);
            }
        }
    },
    mutations: {
        setStripeSessionId( state, sessionId ) { state.stripeSessionId = sessionId; },
        setInvoiceFields( state, field ) { updateField(state.invoice, field); },
        setNewInvoiceFields( state, field ) { updateField(state.newInvoice, field); },
        setInvoiceLoadStatus( state, status ) { state.invoiceLoadStatus = status; },
        setInvoicesLoadStatus( state, status ) { state.invoicesLoadStatus = status; },
        setInvoiceAddStatus( state, status ) { state.invoiceAddStatus = status; },
        setInvoice( state, invoice ) { state.invoice = invoice; },
        setNewInvoice( state, invoice ) { state.newInvoice = invoice; },
        setInvoiceUpdateStatus( state, invoice ) { state.invoiceUpdateStatus = invoice; },
        setInvoices( state, invoices ) { state.invoices = invoices; },
        setInvoiceDeleteStatus(state, status){ state.invoiceDeleteStatus = status;},
        setInvoiceErrors( state, errors ) { state.invoiceErrors = errors; },
        updateInvoiceListStatus( state, invoice = null ) {
            const obj = state.invoices.find(o => o.id === invoice.invoice_id);
            let updatedObj = obj;
            updatedObj.status = invoice.status;
            if(obj) Object.assign( obj, updatedObj);
        },
        updateInvoiceList( state, invoice = null ) {
            const obj = invoice ? state.invoices.find(o => o.id === invoice.id) : state.invoices.find(o => o.id === state.invoice.id);
            if(obj) Object.assign( obj, invoice ? invoice : state.invoice );
            else state.invoices.push(invoice);
            if(invoice && !!state.invoice.id) state.invoice = invoice;
        },
        resetNewInvoice(state) { state.newInvoice = new Invoice() },
        resetInvoiceErrors(state) { state.invoiceErrors = {} },
        ResetInvoice(state) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                state[key] = newState[key]
            });
        },
        setPdfUrl(state, url) {state.pdfUrl = url},
        setPdfFileName(state, file_name) {state.pdfFile_name = file_name},
        downloadInvoicePdf(state){
            let url = state.pdfUrl;
            let link = document.createElement('a');
            link.href = url;
            link.download = state.pdfFile_name;
            link.dispatchEvent(new MouseEvent('click'));
        }
    },

    getters: {
        getInvoiceFields: state => getField(state.invoice),
        getNewInvoiceFields: state => getField(state.newInvoice),
        getNewInvoice: state => state.newInvoice,
        getInvoice: state => state.invoice,
        getInvoices: state => state.invoices,
        getActiveInvoice: state => !!state.invoice.id ? state.invoice.id : null,
        getInvoiceErrors: state => state.invoiceErrors,
        getInvoiceLoadStatus: state => state.invoiceLoadStatus,
        getInvoicesLoadStatus: state => state.invoicesLoadStatus,
        getInvoiceAddStatus: state => state.invoiceAddStatus,
        getInvoiceUpdateStatus: state => state.invoiceUpdateStatus,
        getInvoiceDeleteStatus: state => state.invoiceDeleteStatus,
        getStripeSessionId: state => state.stripeSessionId,
    }
}
