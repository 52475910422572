import API from '@/services/api';
import Location from "../models/Location";
import LocationSpecifications from "../models/LocationSpecifications";
import LocationSpace from "../models/LocationSpace";
import { getField, updateField } from 'vuex-map-fields';

const initialState = () => ({
    newLocation: new Location(),
    location: new Location(),
    newLocationSpecifications: new LocationSpecifications(),
    locationSpecifications: new LocationSpecifications(),
    newLocationSpace: new LocationSpace(),
    locationSpace: new LocationSpace(),
    locations: [],
    locationSearch: [],
    locationLoadStatus: 0,
    locationsLoadStatus: 0,
    locationAddStatus: 0,
    locationUpdateStatus: 0,
    locationDeleteStatus: 0,
    locationSearchStatus: 0,
    locationErrors: {},
});

export default {
    state: initialState(),
    actions: {
        async loadLocations({ commit, state, dispatch }, payload = {} ) {
                let response = await API.getList('Location', payload);
                if(response.status === 200)
                {
                    commit( 'setLocations', response.data.data );
                    commit( 'setLocationsLoadStatus', 2 );
                    if(!state.location.id && !!response.data.data.length > 0) {
                        await dispatch('loadLocation', { id: response.data.data[0].id });
                    } else commit( 'setLocationLoadStatus', 2 );
                } else {
                    commit( 'setLocationsLoadStatus', 3 );
                }
        },
        async loadLocation({ commit, state, rootState, getters }, payload = {} ) {
            if(payload.hasOwnProperty('mlforceload') || payload.id !== getters.getActiveLocation ) {
                if(!payload.hasOwnProperty('mlsilent')) commit( 'setLocationLoadStatus', 1 );
                let response = await API.getOne('Location', payload);
                if(response.status === 200) {
                    commit( 'setLocation', response.data.data );
                    commit( 'updateLocationList', response.data.data );
                    commit( 'setLocationLoadStatus', 2 );
                } else {
                    commit( 'setLocationLoadStatus', 3 );
                }
            }
        },
        async addLocation({ commit, state }, payload = {} ) {
            commit( 'setLocationAddStatus', 1 );
            let response = await API.post( 'Location', { ...state.newLocation, ...payload } );
            if(response.status === 200){
                commit( 'setLocationAddStatus', 2 );
                commit( 'resetNewLocation' );
                commit( 'resetLocationErrors' );
                commit( 'updateLocationList', response.data.data );
                commit( 'setLocation', response.data.data );
                commit( 'setLocationLoadStatus', 2 );
            } else if(response.status === 422){
                commit( 'setLocationAddStatus', 3 );
                commit( 'setLocationErrors', response.data.errors );
            } else {
                commit( 'setLocationAddStatus', 3 );
                commit( 'setLocationErrors', {} );
            }
        },
        async updateLocation({ commit, state }, payload = {} ) {
            commit( 'setLocationUpdateStatus', 1 );
            let response = await API.put( 'Location', { ...state.location, ...payload } );
            if(response.status === 200 || response.status === 202) {
                commit( 'setLocationUpdateStatus', 2 );
                commit( 'resetLocationErrors' );
                commit( 'updateLocationList' );
            } else if(response.status === 422) {
                commit( 'setLocationUpdateStatus', 3 );
                commit( 'setLocationErrors', response.data.errors );
            } else {
                commit( 'setLocationUpdateStatus', 3 );
                commit( 'setLocationErrors', {} );
            }
        },
    },
    mutations: {
        setLocationFields( state, field ) { updateField(state.location, field); },
        setNewLocationFields( state, field ) { updateField(state.newLocation, field); },
        setLocationLoadStatus( state, status ) { state.locationLoadStatus = status; },
        setLocationsLoadStatus( state, status ) { state.locationsLoadStatus = status; },
        setLocationAddStatus( state, status ) { state.locationAddStatus = status; },
        setLocation( state, location ) { state.location = location; },
        setNewLocation( state, location ) { state.newLocation = location; },
        setLocationUpdateStatus( state, location ) { state.locationUpdateStatus = location; },
        setLocations( state, locations ) { state.locations = locations; },
        setLocationErrors( state, errors ) { state.locationErrors = errors; },
        updateLocationListStatus( state, location = null ) {
            const obj = state.locations.find(o => o.id === location.location_id);
            let updatedObj = obj;
            updatedObj.status = location.status;
            if(obj) Object.assign( obj, updatedObj);
        },
        updateLocationList( state, location = null ) {
            const obj = location ? state.locations.find(o => o.id === location.id) : state.locations.find(o => o.id === state.location.id);
            if(obj) Object.assign( obj, location ? location : state.location );
            else state.locations.push(location);
            if(location && !!state.location.id) state.location = location;
        },
        resetNewLocation(state) { state.newLocation = new Location() },
        resetLocationErrors(state) { state.locationErrors = {} },
        ResetLocation(state) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                state[key] = newState[key]
            });
        },
    },

    getters: {
        getLocationFields: state => getField(state.location),
        getNewLocationFields: state => getField(state.newLocation),
        getNewLocation: state => state.newLocation,
        getNewLocationSpecification: state => state.newLocationSpecifications,
        getLocationSpecification: state => state.locationSpecifications,
        getNewLocationSpace: state => state.newLocationSpace,
        getLocationSpace: state => state.locationSpace,
        getLocation: state => state.location,
        getLocations: state => state.locations,
        getActiveLocation: state => !!state.location.id ? state.location.id : null,
        getLocationErrors: state => state.locationErrors,
        getLocationLoadStatus: state => state.locationLoadStatus,
        getLocationsLoadStatus: state => state.locationsLoadStatus,
        getLocationAddStatus: state => state.locationAddStatus,
        getLocationUpdateStatus: state => state.locationUpdateStatus,
        getLocationDeleteStatus: state => state.locationDeleteStatus,
        getLocationsByOrgId: state => organizationId => state.locations.filter( location => location.organization_id === organizationId ),
    }
}
