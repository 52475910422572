export default class Request {
    constructor({
        id = null,
        organization_id = null,
        user_id = null,
        vendor_type = null,
        vendor_id = null,
        vendor_name = null,
        user_name = null,
        user_first_name = null,
        user_last_name = null,
        user_email = null,
        user_phone = null,
        request_no = null,
        is_demo = null,
        status = null,
        created_at = null,
        updated_at = null,
        deleted_at = null,
    } = {}) {
        this.id = id;
        this.organization_id = organization_id;
        this.user_id = user_id;
        this.vendor_type = vendor_type;
        this.vendor_id = vendor_id;
        this.vendor_name = vendor_name;
        this.user_name = user_name;
        this.user_first_name = user_first_name;
        this.user_last_name = user_last_name;
        this.user_email = user_email;
        this.user_phone = user_phone;
        this.request_no = request_no;
        this.is_demo = is_demo;
        this.status = status;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.deleted_at = deleted_at;
    }
}