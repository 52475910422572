export default class Invoice {
    constructor({
        id = null,
        account_id= null,
        organization_id = null,
        subscription_id= null,
        number = null,
        type = null,
        billed_date = null,
        due_date = null,
        currency = null,
        discount_percentage = null,
        transaction_id = null,
        is_demo = null,
        status = null,
        created_at = null,
        updated_at = null,
        deleted_at = null,
    } = {}) {
        this.id = id;
        this.account_id = account_id;
        this.organization_id = organization_id;
        this.subscription_id = subscription_id;
        this.number = number;
        this.type = type;
        this.billed_date = billed_date;
        this.due_date = due_date;
        this.currency = currency;
        this.discount_percentage = discount_percentage;
        this.transaction_id = transaction_id;
        this.is_demo = is_demo;
        this.status = status;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.deleted_at = deleted_at;
    }
}