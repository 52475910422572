import API from '@/services/api';
import Coupon from "../models/Coupon";
import { getField, updateField } from 'vuex-map-fields';

const initialState = () => ({
    newCoupon: new Coupon(),
    coupon: new Coupon(),
    coupons: [],
    couponLoadStatus: 0,
    couponsLoadStatus: 0,
    couponAddStatus: 0,
    couponUpdateStatus: 0,
    couponDeleteStatus: 0,
    couponErrors: {},
});

export default {
    state: initialState(),
    actions: {
        async loadCoupons({ commit, state, dispatch }, payload = {} ) {
            commit( 'setCouponsLoadStatus', 1 );
            let response = await API.getList('Coupon', payload);
            if(response.status === 200)
            {
                commit( 'setCoupons', response.data.data );
                commit( 'setCouponsLoadStatus', 2 );
                if(!state.coupon.id && !!response.data.data.length > 0) {
                    await dispatch('loadCoupon', { id: response.data.data[0].id });
                } else commit( 'setCouponsLoadStatus', 2 );
            } else {
                commit( 'setCouponsLoadStatus', 3 );
            }
        },
        async loadCoupon({ commit, state, rootState, getters }, payload = {} ) {
            if(payload.hasOwnProperty('mlforceload') || payload.id !== getters.getActiveCoupon ) {
                if(!payload.hasOwnProperty('mlsilent')) commit( 'setCouponLoadStatus', 1 );
                commit( 'resetCouponErrors' );
                let response = await API.get('Coupon', payload);
                if(response.status === 200) {
                    commit( 'setCoupon', response.data.data );
                    commit( 'setCouponErrors', {} );
                    commit( 'setCouponLoadStatus', 2 );
                } else {
                    commit( 'setCouponErrors', response.data.errors );
                    commit( 'setCouponLoadStatus', 3 );
                }
            }
        },
        async addCoupon({ commit, state }, payload = {} ) {
            commit( 'setCouponAddStatus', 1 );
            let response = await API.post( 'Coupon', { ...state.newCoupon, ...payload } );
            if(response.status === 200 || response.status === 201){
                commit( 'setCouponAddStatus', 2 );
                commit( 'resetNewCoupon' );
                commit( 'resetCouponErrors' );
                commit( 'updateCouponList', response.data.data );
                commit( 'setCoupon', response.data.data );
                commit( 'setCouponLoadStatus', 2 );
            } else if(response.status === 422){
                commit( 'setCouponAddStatus', 3 );
                commit( 'setCouponErrors', response.data.errors );
            } else {
                commit( 'setCouponAddStatus', 3 );
                commit( 'setCouponErrors', {} );
            }
        },
        async updateCoupon({ commit, state }, payload = {} ) {
            commit( 'setCouponUpdateStatus', 1 );
            let response = await API.put( 'Coupon', { ...state.coupon, ...payload } );
            if(response.status === 200 || response.status === 202) {
                commit( 'setCouponUpdateStatus', 2 );
                commit( 'resetCouponErrors' );
                commit( 'updateCouponList' );
            } else if(response.status === 422) {
                commit( 'setCouponUpdateStatus', 3 );
                commit( 'setCouponErrors', response.data.errors );
            } else {
                commit( 'setCouponUpdateStatus', 3 );
                commit( 'setCouponErrors', {} );
            }
        },
        async deleteCoupon({ commit, state }, payload = {} ) {
            commit('setCouponDeleteStatus',1);
            let response = await API.delete( 'Coupon', payload );
            if(response.status === 200) {
                commit('setCouponDeleteStatus',2);
                commit('removeCoupon', payload);
            }else if(response.status === 444){
                commit('setCouponDeleteStatus',3);
                commit( 'setCouponErrors', {'error':response.data.message} );
            }
        }
    },
    mutations: {
        setCouponFields( state, field ) { updateField(state.coupon, field); },
        setNewCouponFields( state, field ) { updateField(state.newCoupon, field); },
        setCouponLoadStatus( state, status ) { state.couponLoadStatus = status; },
        setCouponsLoadStatus( state, status ) { state.couponsLoadStatus = status; },
        setCouponAddStatus( state, status ) { state.couponAddStatus = status; },
        setCoupon( state, coupon ) { state.coupon = coupon; },
        setNewCoupon( state, coupon ) { state.newCoupon = coupon; },
        setCouponUpdateStatus( state, coupon ) { state.couponUpdateStatus = coupon; },
        setCoupons( state, coupons ) { state.coupons = coupons; },
        setCouponDeleteStatus(state, status){ state.couponDeleteStatus = status;},
        setCouponById( state, id ) { state.coupon = state.coupons.find(o => o.id === id); },
        setCouponErrors( state, errors ) { state.couponErrors = errors; },
        updateCouponListStatus( state, coupon = null ) {
            const obj = state.coupons.find(o => o.id === coupon.coupon_id);
            let updatedObj = obj;
            updatedObj.status = coupon.status;
            if(obj) Object.assign( obj, updatedObj);
        },
        updateCouponList( state, coupon = null ) {
            const obj = coupon ? state.coupons.find(o => o.id === coupon.id) : state.coupons.find(o => o.id === state.coupon.id);
            if(obj) Object.assign( obj, coupon ? coupon : state.coupon );
            else state.coupons.push(coupon);
            if(coupon && !!state.coupon.id) state.coupon = coupon;
        },
        resetNewCoupon(state) { state.newCoupon = new Coupon() },
        resetCouponErrors(state) { state.couponErrors = {} },
        ResetCoupon(state) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                state[key] = newState[key]
            });
        },
        removeCoupon(state, coupon){
            let removeIndex = state.coupons.findIndex(x => x.id === coupon.id);
            state.coupons.splice(removeIndex, 1);
        }
    },

    getters: {
        getCouponFields: state => getField(state.coupon),
        getNewCouponFields: state => getField(state.newCoupon),
        getNewCoupon: state => state.newCoupon,
        getCoupon: state => state.coupon,
        getCoupons: state => state.coupons,
        getActiveCoupon: state => !!state.coupon.id ? state.coupon.id : null,
        getCouponErrors: state => state.couponErrors,
        getCouponLoadStatus: state => state.couponLoadStatus,
        getCouponsLoadStatus: state => state.couponsLoadStatus,
        getCouponAddStatus: state => state.couponAddStatus,
        getCouponUpdateStatus: state => state.couponUpdateStatus,
        getCouponDeleteStatus: state => state.couponDeleteStatus,
    }
}
