import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";
import modules from "./modules";
import router from "@/router";

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== "production";

/* Statuses for Stores
    0 - inital state
    1 - loading with loader (request sent with loader active)
    2 - loaded successfully
    3 - loaded unsuccessfully
 */

export default new Vuex.Store({
    modules,
    actions: {
        ResetAll({ commit }) {
            localStorage.removeItem("mluser");
            localStorage.removeItem("mltoken");
            localStorage.removeItem("expires_at");
            // resets state of all the modules
            Object.keys(modules).forEach(moduleName => {
                commit(`Reset${moduleName}`);
            });
            // router.beforeEach(async(to, from, next) => {
            //     console.log('TO', to.name);
            //     if (to.name !== 'login') next({ name: 'login' });
            //     else next();
            // });
            router.push({ name: "login" }).catch(() => {});
        }
    },
    strict: debug,
    plugins: debug ? [createLogger()] : [] // set logger only for development
});
