import API from '@/services/api';
import User from "../models/User";
import { getField, updateField } from 'vuex-map-fields';

const initialState = () => ({
    newUser: new User(),
    user: new User(),
    users: [],
    userSearch: [],
    userLoadStatus: 0,
    usersLoadStatus: 0,
    userAddStatus: 0,
    userUpdateStatus: 0,
    userDeleteStatus: 0,
    userSearchStatus: 0,
    userErrors: {},
    changePasswordStatus:0,
    emailSentStatus:0,
    changePasswordErrors: []
});

export default {
    state: initialState(),
    actions: {
        async loadUsers({ commit, state, dispatch }, payload = {} ) {
                let response = await API.getList('User', payload);
                if(response.status === 200)
                {
                    commit( 'setUsers', response.data.data );
                    commit( 'setUsersLoadStatus', 2 );
                    if(!state.user.id && !!response.data.data.length > 0) {
                        await dispatch('loadUser', { id: response.data.data[0].id });
                    } else commit( 'setUserLoadStatus', 2 );
                } else {
                    commit( 'setUsersLoadStatus', 3 );
                }
        },
        async loadUser({ commit, state, rootState, getters }, payload = {} ) {
            if(payload.hasOwnProperty('mlforceload') || payload.id !== getters.getActiveUser ) {
                if(!payload.hasOwnProperty('mlsilent')) commit( 'setUserLoadStatus', 1 );
                let response = await API.getOne('User', payload);
                if(response.status === 200) {
                    commit( 'setUser', response.data.data );
                    commit( 'updateUserList', response.data.data );
                    commit( 'setUserLoadStatus', 2 );
                } else {
                    commit( 'setUserLoadStatus', 3 );
                }
            }
        },
        async addUser({ commit, state }, payload = {} ) {
            commit( 'setUserAddStatus', 1 );
            let response = await API.post( 'User', { ...state.newUser, ...payload } );
            if(response.status === 200 || response.status === 201){
                commit( 'setUserAddStatus', 2 );
                commit( 'resetNewUser' );
                commit( 'resetUserErrors' );
                commit( 'updateUserList', response.data.data );
                commit( 'setUser', response.data.data );
                commit( 'setUserLoadStatus', 2 );
            } else if(response.status === 422){
                commit( 'setUserAddStatus', 3 );
                commit( 'setUserErrors', response.data.errors );
            } else {
                commit( 'setUserAddStatus', 3 );
                commit( 'setUserErrors', {} );
            }
        },
        async updateUser({ commit, state }, payload = {} ) {
            commit( 'setUserUpdateStatus', 1 );
            let response = await API.put( 'User', { ...state.user, ...payload } );
            if(response.status === 200 || response.status === 202) {
                commit( 'setUserUpdateStatus', 2 );
                commit( 'resetUserErrors' );
                commit( 'updateUserList' );
            } else if(response.status === 422) {
                commit( 'setUserUpdateStatus', 3 );
                commit( 'setUserErrors', response.data.errors );
            } else {
                commit( 'setUserUpdateStatus', 3 );
                commit( 'setUserErrors', {} );
            }
        },
        async changePassword ({ commit }, payload = {}) {
            commit( 'setChangePasswordStatus', 1 );
            let response = await API.post( 'ChangePassword', payload);
            if(response.status === 200){
                commit( 'setChangePasswordStatus', 2 );
                commit( 'setChangePasswordErrors', '' );
                return true;
            } else if(response.status === 422 || response.status === 400){
                commit( 'setChangePasswordStatus', 3 );
                commit( 'setChangePasswordErrors', response );
                return false;
            }else {
                commit( 'setChangePasswordStatus', 3 );
                commit( 'setChangePasswordErrors', response );

                return false;
            }
        },
        async deleteUser({ commit, state }, payload = {} ) {
            commit('setUserDeleteStatus',1);
            let response = await API.delete( 'User', payload );
            if(response.status === 200) {
                commit('setUserDeleteStatus',2);
                commit('removeUser', payload);
            }else if(response.status === 444){
                commit('setUserDeleteStatus',3);
                commit( 'setUserErrors', {'error':response.data.message} );
            }
        },
        async passwordResetMail({ commit, state }){
            commit('setEmailSentStatus',1);
            let mail = state.user.email;
            let response = await API.resendPasswordResetMail( 'forgotPassword', {'email':mail});
            if (response.status === 200){
                commit('setEmailSentStatus',2);
            }else{
                commit('setEmailSentStatus',3);
            }
        },
    },
    mutations: {
        setEmailSentStatus(state, status) { state.emailSentStatus = status; },
        setUserFields( state, field ) { updateField(state.user, field); },
        setChangePasswordStatus( state, status ){ state.changePasswordStatus = status; },
        setNewUserFields( state, field ) { updateField(state.newUser, field); },
        setUserById( state, id ) { state.user = state.users.find(o => o.id === id); },
        setUserLoadStatus( state, status ) { state.userLoadStatus = status; },
        setUsersLoadStatus( state, status ) { state.usersLoadStatus = status; },
        setUserAddStatus( state, status ) { state.userAddStatus = status; },
        setUser( state, user ) { state.user = user; },
        setNewUser( state, user ) { state.newUser = user; },
        setUserUpdateStatus( state, user ) { state.userUpdateStatus = user; },
        setUsers( state, users ) { state.users = users; },
        setUserDeleteStatus(state, status){ state.userDeleteStatus = status;},
        setChangePasswordErrors( state, errors ) { state.changePasswordErrors = errors; },
        mergeLocationsToUsers( state, locations ) {
            let users = state.users;
            _.forEach(locations, (location) => { users.push(location); });
            state.users = users;
        },
        setUserErrors( state, errors ) { state.userErrors = errors; },
        updateUserListStatus( state, user = null ) {
            const obj = state.users.find(o => o.id === user.user_id);
            let updatedObj = obj;
            updatedObj.status = user.status;
            if(obj) Object.assign( obj, updatedObj);
        },
        updateUserList( state, user = null ) {
            const obj = user ? state.users.find(o => o.id === user.id) : state.users.find(o => o.id === state.user.id);
            if(obj) Object.assign( obj, user ? user : state.user );
            else state.users.push(user);
            if(user && !!state.user.id) state.user = user;
        },
        resetNewUser(state) { state.newUser = new User() },
        resetUserErrors(state) { state.userErrors = {} },
        ResetUser(state) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                state[key] = newState[key]
            });
        },
        removeUser(state, user){
            let removeIndex = state.users.indexOf(user);
            state.users.splice(removeIndex, 1);
        }
    },

    getters: {
        getEmailSentStatus: state => state.emailSentStatus,
        getUserFields: state => getField(state.user),
        getNewUserFields: state => getField(state.newUser),
        getNewUser: state => state.newUser,
        getUser: state => state.user,
        getUsers: state => state.users,
        getSellers: state => _.filter(state.users,u => u.user_type === 'seller'),
        getActiveUser: state => !!state.user.id ? state.user.id : null,
        getUserErrors: state => state.userErrors,
        getUserLoadStatus: state => state.userLoadStatus,
        getUsersLoadStatus: state => state.usersLoadStatus,
        getUserAddStatus: state => state.userAddStatus,
        getUserUpdateStatus: state => state.userUpdateStatus,
        getUserDeleteStatus: state => state.userDeleteStatus,
        getUsersByOrgId: state => userId => state.users.filter( user => user.user_id === userId ),
        getChangePasswordErrors: state => state.changePasswordErrors,
        getChangePasswordStatus: state => state.changePasswordStatus,
    }
}
