export default class Location {
    constructor({
        id = null,
        organization_id = null,
        name = null,
        location_types = null,
        ptcs = [],
        address = null,
        map_location = null,
        city = null,
        country = null,
        description = null,
        seating_arrangements = [],
        location_acoustic_description = null,
        event_acoustic_description = null,
        equipments = null,
        furniture = [],
        human_resources = [],
        catering = [],
        opening_hours = null,
        seasonal_timings = null,
        event_types = [],
        basic_rent = null,
        full_name = null,
        company = null,
        job_title = null,
        email = null,
        phone = null,
        status = null,
        location_specifications = null,
        location_spaces = null,
        gallery = null,
        drafts = [],
    } = {}) {
        this.id = id;
        this.organization_id = organization_id;
        this.name = name;
        this.location_types = location_types;
        this.ptcs = ptcs;
        this.address = address;
        this.map_location = map_location;
        this.city = city;
        this.country = country;
        this.description = description;
        this.seating_arrangements = seating_arrangements;
        this.location_acoustic_description = location_acoustic_description;
        this.event_acoustic_description = event_acoustic_description;
        this.equipments = equipments;
        this.furniture = furniture;
        this.human_resources = human_resources;
        this.catering = catering;
        this.opening_hours = opening_hours;
        this.seasonal_timings = seasonal_timings;
        this.event_types = event_types;
        this.basic_rent = basic_rent;
        this.full_name = full_name,
        this.company = company,
        this.job_title = job_title,
        this.email = email,
        this.phone = phone,
        this.status = status;
        this.location_specifications = location_specifications;
        this.location_spaces = location_spaces;
        this.gallery = gallery;
        this.drafts = drafts;
    }
}